"use client";

import React, { useState, useEffect, useRef } from "react";
import { Avatar } from "@heroui/avatar";
import { Icon } from "@iconify/react";
import { Button, Badge, Input, Spacer, Radio, RadioGroup, Checkbox, cn, Card, CardBody } from "@heroui/react";
import userService from "../../services/userService";
import Location from '../../enums/location';
import Department from "../../enums/department";
import BirthdayPreference from "../../enums/birthday";
import PaymentPreferences from "../../models/user/paymentPreferences";
import SocialMedia from "../../models/user/socialMedia";
import useToast from "../../hooks/useToast";

const ProfileSettings = React.forwardRef(({ className, ...props }, ref) => {
  const { showToast } = useToast();
  const fileInputRef = useRef(null);

  const [user, setUser] = useState(userService.getUser());
  const [avatarUrl, setAvatarUrl] = useState(user.avatarUrl);

  useEffect(() => {
    if (user && user.avatarUrl !== avatarUrl) {
      setAvatarUrl(user.avatarUrl);
    }
  }, [avatarUrl, user]);

  const [isUpdating, setIsUpdating] = useState(false);

  const [birthdayPreference, setBirthdayPreference] = useState(BirthdayPreference.hide);
  const [displayInCalendar, setDisplayInCalendar] = useState(false);

  const [cashOnly, setCashOnly] = useState(false);
  const [cashAccepted, setCashAccepted] = useState(false);
  const [payPal, setPayPal] = useState(false);
  const [payPalEmail, setPayPalEmail] = useState("");
  const [payPalEmailError, setPayPalEmailError] = useState(false);

  const [linkedIn, setLinkedIn] = useState("");
  const [linkedInError, setLinkedInError] = useState(false);
  const [instagram, setInstagram] = useState("");
  const [instagramError, setInstagramError] = useState(false);
  const [facebook, setFacebook] = useState("");
  const [facebookError, setFacebookError] = useState(false);
  const [tiktok, setTikTok] = useState("");
  const [tiktokError, setTikTokerror] = useState(false);
  const [spotify, setSpotify] = useState("");
  const [spotifyError, setSpotifyError] = useState(false);

  useEffect(() => {
    if (user && user.id) {

      if (user.birthdayPreferences) {
        const preference = Object.keys(BirthdayPreference).find(
          (key) => key.toLowerCase() === user.birthdayPreferences.toLowerCase()
        );
        if (preference) {
          setBirthdayPreference(BirthdayPreference[preference]);
        }
      }

      setDisplayInCalendar(user.displayInCalendar || false);

      setCashOnly(user.paymentPreferences?.cashOnly || false);
      setCashAccepted(user.paymentPreferences?.cashAccepted || false);
      setPayPal(user.paymentPreferences?.payPal || false);
      setPayPalEmail(user.paymentPreferences?.payPalMail || "");

      setLinkedIn(user.socialMedia.linkedIn || "");
      setInstagram(user.socialMedia.instagram || "");
      setFacebook(user.socialMedia.facebook || "");
      setTikTok(user.socialMedia.tiktok || "");
      setSpotify(user.socialMedia.spotify || "");
    }
  }, [user]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email.trim());
  };

  const validateUrl = (url) => {
    const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    return regex.test(url.trim());
  };

  const handleUploadPicture = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadResponse = await userService.uploadProfilePicture(file);
        if (uploadResponse.success) {
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: uploadResponse.user.avatarUrl,
          }));
          showToast("Profilbild erfolgreich aktualisiert.", "success");
        } else {
          showToast("Fehler beim Hochladen des Profilbildes.", "error");
        }
      } catch (error) {
        showToast("Fehler beim Hochladen.", "error");
      }
    }
  };

  const handleSave = async () => {
    setIsUpdating(true);
    let hasErrors = false;

    if (payPal) {
      if (!payPalEmail || !validateEmail(payPalEmail)) {
        showToast("Bitte überprüfe Deine PayPal E-Mail Adresse.", "error");
        setPayPalEmailError(true);
        hasErrors = true;
      } else {
        setPayPalEmailError(false);
      }
    }

    const socialMediaUrls = {
      linkedIn: { value: linkedIn, setError: setLinkedInError },
      instagram: { value: instagram, setError: setInstagramError },
      facebook: { value: facebook, setError: setFacebookError },
      tiktok: { value: tiktok, setError: setTikTokerror },
      spotify: { value: spotify, setError: setSpotifyError },
    };

    for (const [, { value, setError }] of Object.entries(socialMediaUrls)) {
      if (value && !validateUrl(value)) {
        showToast("Bitte überprüfe Deine Social-Media Accounts.", "error");
        setError(true);
        hasErrors = true;
      } else {
        setError(false);
      }
    }    

    if (!hasErrors) {
      const paymentPreferences = new PaymentPreferences({
        cashOnly,
        cashAccepted,
        payPalMail: payPalEmail,
        payPal,
      });

      const socialMedia = new SocialMedia({
        facebook,
        linkedIn,
        instagram,
        tiktok,
        spotify,
      });

      const profileData = {
        birthdayPreference,
        displayInCalendar,
        paymentPreferences,
        socialMedia,
      };

      const { error } = await userService.updateUser(profileData);
      if (error) {
        showToast(error || "Fehler bei der Aktualisierung.", "error");
      } else {
        showToast("Profil aktualisiert", "success");
      }
    }
    setIsUpdating(false);
  };

  useEffect(() => {
  }, [user]);

  return (
    (<div ref={ref} className={cn("p-2", className)} {...props}>
      <div>
        <p className="text-base font-medium text-default-700">Profil</p>
        <p className="mt-1 text-sm font-normal text-default-400">
          So wirst Du Deinen Teamkollegen angezeigt.
        </p>
        <Card className="mt-4 bg-default-100" shadow="none">
          <CardBody>
            <div className="flex items-center gap-4">
              <Badge
                classNames={{
                  badge: "w-5 h-5",
                }}
                content={
                  <Button
                    isIconOnly
                    className="h-5 w-5 min-w-5 bg-background p-0 text-default-500"
                    radius="full"
                    size="sm"
                    variant="bordered"
                    onPress={() => fileInputRef.current.click()}
                  >
                    <Icon className="h-[9px] w-[9px]" icon="solar:pen-linear" />
                  </Button>
                }
                placement="bottom-right"
                shape="circle"
              >
                <Avatar className="h-16 w-16" src={avatarUrl} />
              </Badge>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleUploadPicture}
                accept="image/png, image/jpeg"
              />
              <div>
                <p className="text-sm font-medium text-default-600">{user.firstname} {user.surname}</p>
                <p className="text-xs text-default-400">
                  {user.department && Department[user.department]
                    ? Department[user.department].departmentName
                    : 'Unbekannte Abteilung'},&nbsp;
                  {user.location && Location[user.location]
                    ? Location[user.location].locationName
                    : 'Unbekannter Ort'}
                </p>
                <p className="mt-1 text-xs text-default-400">{user?.mail || 'Keine ZOXS-Mail'}</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <Spacer y={8} />
      <div>
        <p className="text-base font-medium text-default-700">Geburtstagseinstellungen</p>
        <p className="mt-1 text-sm font-normal text-default-400">Darf man Dir gratulieren oder lieber ungerne?</p>
        <Spacer y={4} />
        <div>
          <RadioGroup
            value={birthdayPreference}
            onValueChange={(newValue) => {
              setBirthdayPreference(newValue);
              if (newValue !== BirthdayPreference.showFull) {
                setDisplayInCalendar(false);
              }
            }}
          >
            <Radio value={BirthdayPreference.hide}>
              {BirthdayPreference.hide.description}
            </Radio>
            <Radio value={BirthdayPreference.showDecade}>
              {BirthdayPreference.showDecade.description}
            </Radio>
            <Radio value={BirthdayPreference.showFull}>
              {BirthdayPreference.showFull.description}
            </Radio>
          </RadioGroup>
          <Spacer y={2} />
          <Checkbox
            isSelected={displayInCalendar}
            onValueChange={setDisplayInCalendar}
            isDisabled={birthdayPreference !== BirthdayPreference.showFull}
          >
            Im Kalender anzeigen
          </Checkbox>
        </div>
      </div>
      <Spacer y={8} />
      <div>
        <p className="text-base font-medium text-default-700">Mittagstisch-Zahlungsdaten</p>
        <p className="mt-1 text-sm font-normal text-default-400">Wie kann man Dich bezahlen, wenn Du Besteller bist?</p>
        <Spacer y={4} />
        <div>
          <Checkbox
            isSelected={cashOnly}
            onValueChange={(isSelected) => {
              if (!isSelected && !payPal && !cashAccepted) {
                //
              } else {
                setCashOnly(isSelected);
              }
              if (isSelected) {
                setCashAccepted(false);
                setPayPal(false);
              }
            }}
          >
            Vorkasse only (Bar)
          </Checkbox>
          <Spacer x={2} />
          <Checkbox
            isSelected={cashAccepted}
            onValueChange={(isSelected) => {
              setCashAccepted(isSelected);
              if (isSelected) {
                setCashOnly(false);
              } else if (!payPal) {
                setCashOnly(true);
              }
            }}
          >
            Barzahlung
          </Checkbox>
          <Spacer x={2} />
          <Checkbox
            isSelected={payPal}
            onValueChange={(isSelected) => {
              setPayPal(isSelected);
              if (isSelected) {
                setCashOnly(false);
              } else if (!cashAccepted) {
                setCashOnly(true);
              }
            }}
          >
            PayPal (Freunde)
          </Checkbox>
          <Spacer y={2} />
          <Input
            className="max-w-[500px]"
            isDisabled={!payPal}
            variant="bordered"
            label="PayPal E-Mail"
            placeholder="paypal@zoxs.de"
            value={payPalEmail}
            startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:paypal" width={18} />}
            isInvalid={payPalEmailError}
            onChange={(e) => {
              setPayPalEmail(e.target.value.replace(/\s/g, ''));
              setPayPalEmailError(false);
            }}
          />
        </div>
      </div>
      <Spacer y={8} />
      <div>
        <p className="text-base font-medium text-default-700">Social-Media</p>
        <p className="mt-1 text-sm font-normal text-default-400">Möchtest Du Deine Social-Media Profile verknüpfen?</p>
        <Spacer y={4} />
        <div>
          <Input
            className="max-w-[500px]"
            variant="bordered"
            label="LinkedIn"
            placeholder="LinkedIn Nutzername"
            value={linkedIn}
            startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:linkedin-01" width={18} />}
            isInvalid={linkedInError}
            onChange={(e) => {
              setLinkedIn(e.target.value.replace(/\s/g, ''));
              setLinkedInError(false);
            }}
          />
          <Spacer y={2} />
          <Input
            className="max-w-[500px]"
            variant="bordered"
            label="Instagram"
            placeholder="Instagram Nutzername"
            value={instagram}
            startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:instagram" width={18} />}
            isInvalid={instagramError}
            onChange={(e) => {
              setInstagram(e.target.value.replace(/\s/g, ''));
              setInstagramError(false);
            }}
          />
          <Spacer y={2} />
          <Input
            className="max-w-[500px]"
            variant="bordered"
            label="TikTok"
            placeholder="TikTok Nutzername"
            value={tiktok}
            startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:tiktok" width={18} />}
            isInvalid={tiktokError}
            onChange={(e) => {
              setTikTok(e.target.value.replace(/\s/g, ''));
              setTikTokerror(false);
            }}
          />
          <Spacer y={2} />
          <Input
            className="max-w-[500px]"
            variant="bordered"
            label="Facebook"
            placeholder="Facebook Nutzername"
            value={facebook}
            startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:facebook-01" width={18} />}
            isInvalid={facebookError}
            onChange={(e) => {
              setFacebook(e.target.value.replace(/\s/g, ''));
              setFacebookError(false);
            }}
          />
          <Spacer y={2} />
          <Input
            className="max-w-[500px]"
            variant="bordered"
            label="Spotify"
            placeholder="Spotify Nutzername"
            value={spotify}
            startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="hugeicons:spotify" width={18} />}
            isInvalid={spotifyError}
            onChange={(e) => {
              setSpotify(e.target.value.replace(/\s/g, ''));
              setSpotifyError(false);
            }}
          />
        </div>
      </div>
      <Button isLoading={isUpdating} className="mt-4 bg-primary text-background" size="md" onPress={handleSave}>
        Speichern
      </Button>
    </div>)
  );
});

ProfileSettings.displayName = "ProfileSettings";

export default ProfileSettings;