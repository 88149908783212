import React, { useEffect, useState } from "react";
import { Tab, Tabs, Spinner, Button, Select, SelectItem } from "@heroui/react";
import zoxsManagementService from "@/services/zoxsManagementService";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AppPushItem from "@/components/AppPushItem";

const AppPushs = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [pushFeed, setPushFeed] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const { pushs: pushs } = await zoxsManagementService.getPushs();

                setPushFeed(pushs);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCreatePushCampaign = () => {
        navigate('/admin-push/create');
    };

    return (
        <div className="w-full h-full flex-1 p-4" style={{ overflowY: "auto" }}>
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">ZOXS App: Push-Notifications</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500">
                Informiere hier alle App-Nutzer über anstehende Kampagnen.
            </h2>

            <div className="flex items-center gap-x-3 mt-4">
                <div className="flex-grow" />
                <Button size="lg" color="primary" onPress={handleCreatePushCampaign}>
                    Push-Kampagne erstellen
                </Button>
            </div>

            {isLoading ? (
                <Spinner size="large" />
            ) : (<div className="mt-4 space-y-4">
                {pushFeed.length > 0 ? (
                    pushFeed.map((item) => (
                        <div key={item.id} className="space-y-4">
                            <AppPushItem item={item} />
                        </div>
                    ))
                ) : (
                    <p>Noch keine Pushs vorhanden</p>
                )}
            </div>
            )}
        </div>
    );
}

export default AppPushs;