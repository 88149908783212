import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';
import WebDesignerOverView from './DesignerOverview';
import WebDesigner from './Designer';

const WebDesignerPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    const [activeComponent, setActiveComponent] = useState('Overview');
    const userHasPermission = userService.user.modules.includes(15);

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/admin-webdesigner/create')) {
            setActiveComponent('WebDesignerCreate');
        } else if (path.startsWith('/admin-webdesigner/edit') && id) {
            setActiveComponent('WebDesignerEdit');
        } else if (path.startsWith('/admin-webdesigner/preview') && id) {
            setActiveComponent('WebDesignerPreview');
        } else {
            setActiveComponent('Overview');
        }
    }, [location.pathname, id]);

    if (!userHasPermission) {
        return <NoPermission />;
    }

    return (
        <>
            {activeComponent === 'Overview' && <WebDesignerOverView />}
            {activeComponent === 'WebDesignerCreate' && <WebDesigner />}
            {activeComponent === 'WebDesignerEdit' && <WebDesigner id={id} />}
            {activeComponent === 'WebDesignerPreview' && <div id={id} />}
        </>
    );
};

export default WebDesignerPage;
