"use client";

import React from "react";
import { Radio, RadioGroup, Select, SelectItem, Textarea } from "@heroui/react";
import { cn } from "@heroui/react";
import { pushAudienceOptions } from "./types";
import { useAppPushCreator } from "./AppPushCreator";

const AppPushCreatorTarget = React.forwardRef(({ className, ...props }, ref) => {
  const { state, updateField } = useAppPushCreator();

  const radioClassNames = {
    base: cn(
      "inline-flex m-0 bg-default-100 items-center justify-between",
      "flex-row-reverse w-full max-w-full cursor-pointer rounded-xl p-4 border-medium border-transparent",
      "data-[selected=true]:border-primary",
    ),
    control: "bg-primary text-secondary-foreground",
    wrapper: "group-data-[selected=true]:border-primary",
    label: "text-small text-default-500 font-medium",
    labelWrapper: "m-0",
  };

  const handleAudienceChange = (value) => {
    updateField("userIdsError", false);
    updateField("audience", value);
  };

  return (<>
    <div className="text-3xl font-bold leading-9 text-default-foreground">
      Zielgruppe
    </div>
    <div className="py-4 text-default-500">
      Ausgeloggte Nutzer erhalten Push-Notifications in der Public-Group. Für eingeloggte Nutzer wird die Benachrichtigung in ein persönliches Postfach geschrieben.
    </div>
    <form
      ref={ref}
      className={cn("flex grid grid-cols-12 flex-col gap-4 py-8", className)}
      {...props}
    >
      <RadioGroup
        className="col-span-12"
        classNames={{
          wrapper: "gap-4",
        }}
        name="audience"
        value={state.audience}
        onValueChange={handleAudienceChange}
      >
        {pushAudienceOptions.map((option) => (
          <Radio classNames={radioClassNames}
            key={option.value}
            value={option.value}
            isDisabled={!option.available}
          >
            {option.label}
          </Radio>
        ))}
      </RadioGroup>
      <Textarea
        className="col-span-12"
        variant="bordered"
        type="text"
        size="lg"
        label="Kundennummern"
        isDisabled={state.audience !== 'custom'}
        value={state.userIds}
        placeholder="Kommagetrennte Eingabe von Kundennummern"
        labelPlacement="inside"
        isInvalid={state.userIdsError}
        onChange={(e) => {
          const value = e.target.value.replace(/[^0-9,]/g, '');
          updateField("userIds", value);
          updateField("userIdsError", false);
        }}
      />
    </form>
  </>);
});

AppPushCreatorTarget.displayName = "AppPushCreatorTarget";

export default AppPushCreatorTarget;
