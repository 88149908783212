import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    User,
    Badge,
    Avatar,
    Button,
    ScrollShadow,
    Card,
    CardBody,
    CardFooter,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownSection,
    DropdownTrigger,
    Input,
    Spacer,
    Image,
    useDisclosure,
    Kbd,
    Modal,
    ModalHeader,
    ModalBody,
    ModalContent
} from "@heroui/react";
import { Icon } from "@iconify/react";
import authController from "../../api/authController";
import useToast from "../../hooks/useToast";
import { useLocation, useNavigate } from "react-router-dom";
import Department from "../../enums/department";
import Location from "../../enums/location";
import AppDownloadContent from "../../components/AppDownloadContent";
import userService from "@/services/userService";
import notificationService from "@/services/notificationService";
import { useMenuItems } from "./menuItems";
import Sidebar from "./Sidebar";
import SidebarDrawer from "./SidebarDrawer";
import NotificationComponent from "./NotificationCard";

export default function AppHomePage() {
    const { showToast } = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    // NOTIFICATIONS
    const [unreadCount, setUnreadCount] = useState(0);
    useEffect(() => {
        notificationService.subscribeToNotifications();

        const unsubscribe = notificationService.addListener(setUnreadCount);

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
            notificationService.unsubscribe();
        };
    }, []);

    // USER REFRESH & LOGOUT
    const handleRefreshUser = () => {
        userService.refreshUser().then((response) => {
            if (response.user) {
                showToast("Aktualisierung erfolgreich!", "success");
            } else {
                showToast(response.error || "Fehler bei der Aktualisierung.", "error");
            }
        });
    };

    const logout = () => {
        authController.logoutUser();
        navigate("/auth");
        showToast("Logout erfolgreich!", "success");
    };

    // APP DOWNLOAD POPUP
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { isOpen: isModOpen, onOpen: onModOpen, onOpenChange: onModOpenChange } = useDisclosure();


    // SEARCH RELATED
    // TODO: IMPLEMENT

    // MODULE HANDLING
    const sidebarItems = useMenuItems();
    const [currentModule, setCurrentModule] = useState(null);

    useEffect(() => {
        if (currentModule) {
            return;
        }

        const firstValidItem = sidebarItems.reduce((acc, section) => {
            if (!acc && section.items) {
                acc = section.items.find(item => item.key !== null);
            }
            return acc;
        }, null);

        if (firstValidItem) {
            setCurrentModule(firstValidItem.key || "feed");
        }
    }, [sidebarItems]);

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const moduleFromPath = pathSegments[1] ? decodeURIComponent(pathSegments[1].toLowerCase()) : null;

        if (!moduleFromPath) {
            return;
        }

        const matchedItem = sidebarItems.reduce((acc, item) => {
            acc.push(item);
            if (item.items) {
                item.items.forEach(subItem => {
                    acc.push(subItem);
                });
            }
            return acc;
        }, []).find(item => item.key.toLowerCase() === moduleFromPath);

        if (matchedItem) {
            if (matchedItem.component) {
                setCurrentModule(matchedItem.key);
            } else {
                const firstValidItem = sidebarItems.reduce((acc, section) => {
                    if (!acc && section.items) {
                        acc = section.items.find(item => item.key !== null);
                    }
                    return acc;
                }, null);

                if (firstValidItem) {
                    setCurrentModule(firstValidItem.key || "feed");
                }
            }
        }
    }, [location, sidebarItems]);

    const handleSetCurrentModule = (moduleKey) => {
        const matchedItem = sidebarItems.reduce((acc, item) => {
            acc.push(item);
            if (item.items) {
                item.items.forEach(subItem => {
                    acc.push(subItem);
                });
            }
            return acc;
        }, []).find(item => item.key === moduleKey);

        if (matchedItem) {
            navigate(`/${encodeURIComponent(matchedItem.key)}`);
            setCurrentModule(matchedItem.key);
        } else {
            navigate('/');
            setCurrentModule("feed");
        }
    };

    const renderModule = () => {
        const module = sidebarItems
            .flatMap(section => section.items)
            .find(item => item.key === currentModule);

        return module ? (
            <div>
                <module.component />
            </div>
        ) : (
            <div>Module not found: {currentModule}</div>
        );
    };

    const content = (
        <div className="relative flex h-full w-72 flex-col p-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 px-2">
                    <span className="font-medium">inteam</span>
                </div>
                <div className="flex items-center justify-end">
                    <Dropdown showArrow backdrop="blur">
                        <DropdownTrigger>
                            <Button disableRipple isIconOnly className="-mr-1" radius="full" variant="light">
                                <Avatar className="h-6 w-6 cursor-pointer" name={`${userService.user.firstname} ${userService.user.surname}`} src={userService.user.avatarUrl} />
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Custom item styles" disabledKeys={["profile"]}>
                            <DropdownSection showDivider aria-label="Profile & Actions">
                                <DropdownItem
                                    key="profile"
                                    isReadOnly
                                    className="h-14 gap-2 opacity-100"
                                    textValue="Signed in as"
                                >
                                    <User
                                        avatarProps={{ size: "sm", src: userService.user.avatarUrl, imgProps: { className: "transition-none" } }}
                                        classNames={{ name: "text-default-600", description: "text-default-500" }}
                                        description={`${userService.user.department && Department[userService.user.department]
                                            ? Department[userService.user.department].departmentName
                                            : 'Unbekannte Abteilung'}, ${userService.user.location && Location[userService.user.location]
                                                ? Location[userService.user.location].locationName
                                                : 'Unbekannter Ort'
                                            }`}
                                        name={`${userService.user.firstname} ${userService.user.surname}`}
                                    />
                                </DropdownItem>
                                <DropdownItem
                                    onClick={handleRefreshUser}
                                    description="Rechte aktualisieren"
                                    startContent={<Icon className="text-default-500" icon="solar:refresh-linear" width={18} />}
                                >
                                    Aktualisieren
                                </DropdownItem>
                            </DropdownSection>

                            <DropdownSection aria-label="Logout">
                                <DropdownItem
                                    onClick={logout}
                                    description="Schönen Feierabend"
                                    startContent={<Icon className="text-default-500" icon="solar:logout-3-linear" width={18} />}
                                >
                                    Logout
                                </DropdownItem>
                            </DropdownSection>
                        </DropdownMenu>
                    </Dropdown>
                    <Popover offset={12} showArrow backdrop="blur">
                        <PopoverTrigger>
                            <Button disableRipple isIconOnly className="overflow-visible" radius="full" variant="light">
                                <Badge color="primary" content={unreadCount} showOutline={false} size="md" isInvisible={unreadCount === 0}>
                                    <Icon className="text-default-500" icon="solar:bell-linear" width={22} />
                                </Badge>
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="max-w-[90vw] p-0 sm:max-w-[380px]">
                            <NotificationComponent className="w-full shadow-none" />
                        </PopoverContent>
                    </Popover>
                </div>
            </div>

            {/* <Spacer y={4} />

            <div className="flex flex-col gap-y-2">
                <Input
                    fullWidth
                    aria-label="search"
                    labelPlacement="outside"
                    placeholder='Suche...'
                    variant="bordered"
                    endContent={<Kbd keys={["command"]}>K</Kbd>}
                    startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="solar:magnifer-linear" width={18} />}
                />
            </div> */}

            <ScrollShadow className="-mr-6 h-full max-h-full py-4 pr-4">
                <Sidebar
                    items={sidebarItems}
                    defaultSelectedKey={currentModule}
                    onSelect={handleSetCurrentModule}
                />
                <Spacer y={2} />
                <Card className="mx-2 overflow-visible" shadow="sm">
                    <CardBody className="items-center py-5 text-center">
                        <h3 className="text-medium font-medium text-default-700">
                            inteam App 📱
                        </h3>
                        <p className="p-4 text-small text-default-500">
                            Lade Dir jetzt die ZOXS inteam App auf Dein Smartphone herunter, und nutze noch mehr Features auch von unterwegs.
                        </p>
                    </CardBody>
                    <CardFooter className="absolute -bottom-8 justify-center">
                        <Button className="px-10" color="primary" radius="full" onPress={onModOpen}>
                            Download
                        </Button>
                    </CardFooter>
                </Card>
            </ScrollShadow>
        </div>
    );

    return (
        <>
            <div className="flex h-dvh w-full">
                <SidebarDrawer
                    className="flex-none border-r-small border-divider overflow-hidden"
                    isOpen={isOpen}
                    onOpenChange={onOpenChange}
                >
                    {content}
                </SidebarDrawer>
                <div className="relative flex w-full flex-col gap-y-0 sm:max-w-[calc(100%_-_288px)]">
                    <header className="flex h-16 min-h-16 items-center justify-between px-4 w-full bg-white shadow z-10 sm:hidden">
                        <div className="flex max-w-full items-center gap-2">
                            <Button isIconOnly className="flex" size="sm" variant="light" onPress={onOpen}>
                                <Icon className="text-default-500" height={24} icon="solar:widget-outline" width={24} />
                            </Button>
                            <h2 className="truncate text-medium font-medium text-default-700">inteam</h2>
                        </div>
                        <div className="ml-auto flex items-center gap-2">
                            <Dropdown showArrow backdrop="blur">
                                <DropdownTrigger>
                                    <Button disableRipple isIconOnly className="-mr-1" radius="full" variant="light">
                                        <Avatar className="h-6 w-6 cursor-pointer" name={`${userService.user.firstname} ${userService.user.surname}`} src={userService.user.avatarUrl} />
                                    </Button>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Custom item styles" disabledKeys={["profile"]}>
                                    <DropdownSection showDivider aria-label="Profile & Actions">
                                        <DropdownItem
                                            key="profile"
                                            isReadOnly
                                            className="h-14 gap-2 opacity-100"
                                            textValue="Signed in as"
                                        >
                                            <User
                                                avatarProps={{ size: "sm", src: userService.user.avatarUrl, imgProps: { className: "transition-none" } }}
                                                classNames={{ name: "text-default-600", description: "text-default-500" }}
                                                description={`${userService.user.department && Department[userService.user.department]
                                                    ? Department[userService.user.department].departmentName
                                                    : 'Unbekannte Abteilung'}, ${userService.user.location && Location[userService.user.location]
                                                        ? Location[userService.user.location].locationName
                                                        : 'Unbekannter Ort'
                                                    }`}
                                                name={`${userService.user.firstname} ${userService.user.surname}`}
                                            />
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={handleRefreshUser}
                                            description="User updaten"
                                            startContent={<Icon className="text-default-500" icon="solar:refresh-linear" width={18} />}
                                        >
                                            Aktualisieren
                                        </DropdownItem>
                                    </DropdownSection>
                                    <DropdownSection aria-label="Logout">
                                        <DropdownItem
                                            onClick={logout}
                                            description="Schönen Feierabend"
                                            startContent={<Icon className="text-default-500" icon="solar:logout-3-linear" width={18} />}
                                        >
                                            Logout
                                        </DropdownItem>
                                    </DropdownSection>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </header>
                    <main className="flex-grow w-full overflow-auto">
                        {renderModule()}
                    </main>
                </div>
            </div>
            <Modal
                isOpen={isModOpen}
                onOpenChange={onModOpenChange}
                backdrop='blur'
            >
                <ModalContent>
                    {(onModClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">App-Download</ModalHeader>
                            <ModalBody>
                                <AppDownloadContent />
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
