import { Button, Image } from "@heroui/react";
import mapZoxsy from "../assets/svg/zoxsy_location.svg";
import confetti from "canvas-confetti";

function ComingSoon() {
    const triggerConfettiFromButton = (event) => {
        const rect = event.target.getBoundingClientRect();
        const x = rect.left + rect.width / 2;
        const y = rect.top + rect.height / 2;

        confetti({
            particleCount: 200,
            spread: 300,
            startVelocity: 30,
            origin: {
                x: x / window.innerWidth,
                y: y / window.innerHeight,
            },
        });
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="p-6 bg-background shadow-lg rounded-lg" css={{ maxWidth: "500px", textAlign: "center" }}>
                <div className="flex justify-center mb-4">
                    <Image
                        width={215}
                        alt="Map ZOXSY"
                        src={mapZoxsy}
                    />
                </div>
                <h2 className="text-xl font-bold mb-2 text-center">Du bist zu früh!</h2>
                <p className="text-default-500 mb-6 text-center">
                    Diese Funktion steht aber als nächstes auf der Roadmap und kommt bald.
                </p>
                <div className="flex flex-col gap-3">
                    <Button color="primary" onPress={triggerConfettiFromButton}>
                        Nice!
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;
