export interface SickHistoryItem {
    id: number;
    start: string;
    canEdit: boolean;
    end?: string;
    comment?: string;
}

export const defaultSickHistoryItem: SickHistoryItem = {
    id: 0,
    start: "",
    canEdit: false,
};

export const sickHistoryItemFromJson = (json: any): SickHistoryItem => {
    return {
      id: json.id,
      start: json.start,
      canEdit: json.canEdit || false,
      end: json.end || undefined,
      comment: json.comment || undefined,
    };
  };