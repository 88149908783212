"use client";

import React from "react";
import { DatePicker, Radio, RadioGroup, Select, SelectItem } from "@heroui/react";
import { cn } from "@heroui/react";
import { useAppPushCreator } from "./AppPushCreator";
import { pushTimeSettings } from "./types";
import { I18nProvider } from "@react-aria/i18n";
import { getLocalTimeZone, now, parseAbsoluteToLocal, today } from "@internationalized/date";

const AppPushCreatorTime = React.forwardRef(({ className, ...props }, ref) => {
    const { state, updateField } = useAppPushCreator();

    const radioClassNames = {
        base: cn(
            "inline-flex m-0 bg-default-100 items-center justify-between",
            "flex-row-reverse w-full max-w-full cursor-pointer rounded-xl p-4 border-medium border-transparent",
            "data-[selected=true]:border-primary",
        ),
        control: "bg-primary text-secondary-foreground",
        wrapper: "group-data-[selected=true]:border-primary",
        label: "text-small text-default-500 font-medium",
        labelWrapper: "m-0",
    };

    const handleTimeChange = (value) => {
        updateField("planned", value);
    };

    const changeTime = (value) => {
        const data = new Date(
            value.year,
            value.month - 1,
            value.day,
            value.hour,
            value.minute,
            value.second,
            value.millisecond
        );
        updateField("time", data);
    };

    return (
        <>
            <div className="text-3xl font-bold leading-9 text-default-foreground">
                Terminierung
            </div>
            <div className="py-4 text-default-500">
                Wann soll die Benachrichtigung versendet werden? Alle Benachrichtigungen, die in weniger als 15 Minuten versendet werden sollen, werden direkt verschickt.
            </div>
            <form
                ref={ref}
                className={cn("flex grid grid-cols-12 flex-col gap-4 py-8", className)}
                {...props}
            >
                <RadioGroup
                    className="col-span-12"
                    classNames={{
                        wrapper: "gap-4",
                    }}
                    name="planned"
                    value={state.planned}
                    onValueChange={handleTimeChange}
                >
                    {pushTimeSettings.map((option) => (
                        <Radio
                            classNames={radioClassNames}
                            key={option.value}
                            value={option.value}
                            isDisabled={
                                option.excludeIf.includes(state.audience)
                            }
                        >
                            {option.label}
                        </Radio>
                    ))}
                </RadioGroup>
                <I18nProvider locale="de">
                    <DatePicker
                        className="col-span-12"
                        variant="bordered"
                        size="lg"
                        label="Startzeit"
                        granularity="minute"
                        minValue={now(getLocalTimeZone()).add({ minutes: 20 })}
                        value={parseAbsoluteToLocal(state.time.toISOString())}
                        labelPlacement="outside-left"
                        visibleMonths={2}
                        onChange={changeTime}
                        isDisabled={state.planned === "now"}
                    />
                </I18nProvider>
            </form>
        </>
    );
});

AppPushCreatorTime.displayName = "AppPushCreatorTime";

export default AppPushCreatorTime;
