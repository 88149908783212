import React, { useEffect, useState } from "react";
import { Tab, Tabs, Spinner, Button, Select, SelectItem } from "@heroui/react";
import zoxsManagementService from "@/services/zoxsManagementService";
import AppFeedItem from "@/components/AppFeedItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";

const AppPromotions = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [zoxsPromotions, setZoxsPromotions] = useState([]);
  const [buyZoxsPromotions, setBuyZoxsPromotions] = useState([]);
  const [activeTab, setActiveTab] = useState("zoxs");
  const [sortOption, setSortOption] = useState("order");
  const [showInactive, setShowInactive] = useState("show");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { promotions: zoxs } = await zoxsManagementService.getPromotions("zoxs");
        const { promotions: buyZoxs } = await zoxsManagementService.getPromotions("buyzoxs");

        setZoxsPromotions(zoxs);
        setBuyZoxsPromotions(buyZoxs);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getActivePromotions = () => {
    switch (activeTab) {
      case "zoxs":
        return zoxsPromotions;
      case "buyzoxs":
        return buyZoxsPromotions;
      default:
        return [];
    }
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handleInactiveChange = (e) => {
    setShowInactive(e.target.value);
  };

  const sortedPromotions = [...getActivePromotions()].sort((a, b) => {
    if (sortOption === "order") return a.order - b.order;
    if (sortOption === "updated") return new Date(b.updated) - new Date(a.updated);
    return new Date(b.created) - new Date(a.created);
  });

  const filteredPromotions = showInactive === "show"
    ? sortedPromotions
    : sortedPromotions.filter((item) => {
      const now = new Date();
      return now >= new Date(item.start) && now <= new Date(item.end);
    });

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const activePromotionsCopy = [...getActivePromotions()];
    const [movedItem] = activePromotionsCopy.splice(result.source.index, 1);
    activePromotionsCopy.splice(result.destination.index, 0, movedItem);

    activePromotionsCopy.forEach((item, index) => {
      item.order = index + 1;
    });

    const updatedOrderList = activePromotionsCopy.map(({ id, order }) => ({ id, order }));

    switch (activeTab) {
      case "zoxs":
        setZoxsPromotions(activePromotionsCopy);
        break;
      case "buyzoxs":
        setBuyZoxsPromotions(activePromotionsCopy);
        break;
      default:
        break;
    }

    zoxsManagementService.updatePromotionOrder(updatedOrderList)
      .then(() => {
      })
      .catch(error => {
      });
  };

  const handleCreatePromotion = () => {
    navigate('/admin-promotion/create');
  };

  return (
    <div className="w-full h-full flex-1 p-4" style={{ overflowY: "auto" }}>
      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">ZOXS App: Promotions</h1>
      </div>
      <h2 className="mt-2 text-small text-default-500">
        Verwalte den Inhalt der verschiedener Promotions und erhalte Statistiken.
      </h2>

      <div className="flex items-center gap-x-3 mt-4">
        <Select
          label="Sortieren nach"
          selectionMode="single"
          defaultSelectedKeys={[sortOption]}
          selectedKeys={[sortOption]}
          variant="bordered"
          onChange={handleSortChange}
          className="max-w-xs"
          disallowEmptySelection
          size="sm"
        >
          <SelectItem key="order">Reihenfolge</SelectItem>
          <SelectItem key="updated">Aktualisiert</SelectItem>
          <SelectItem key="created">Erstellt</SelectItem>
        </Select>
        <Select
          label="Inaktive Einträge"
          selectionMode="single"
          defaultSelectedKeys={[showInactive]}
          selectedKeys={[showInactive]}
          onChange={handleInactiveChange}
          className="max-w-xs"
          variant="bordered"
          disallowEmptySelection
          size="sm"
        >
          <SelectItem key="show">Inaktive zeigen</SelectItem>
          <SelectItem key="hide">Inaktive verbergen</SelectItem>
        </Select>
        <div className="flex-grow" />
        <Button size="lg" color="primary" onPress={handleCreatePromotion}>
          Promotion erstellen
        </Button>
      </div>

      <Tabs
        fullWidth
        radius="full"
        classNames={{
          base: "mt-6",
          cursor: "bg-content1 dark:bg-content1",
          panel: "w-full p-0 pt-4",
        }}
        onSelectionChange={(tabKey) => setActiveTab(tabKey)}
        selectedKey={activeTab}
      >
        <Tab key="zoxs" title="ZOXS-Promotions" />
        <Tab key="buyzoxs" title="buyZOXS-Promotions" />
      </Tabs>

      {isLoading ? (
        <Spinner size="large" />
      ) : (
        sortOption === "order" && showInactive === "show" ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="promotions-list">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="mt-4 space-y-4"
                >
                  {filteredPromotions.length > 0 ? (
                    filteredPromotions.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="space-y-4"
                          >
                            <AppFeedItem item={item} isFeedModel={false} />
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <p>Noch keine Promotions vorhanden</p>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="mt-4 space-y-4">
            {filteredPromotions.length > 0 ? (
              filteredPromotions.map((item) => (
                <div key={item.id} className="space-y-4">
                  <AppFeedItem item={item} isFeedModel={false} />
                </div>
              ))
            ) : (
              <p>Noch keine Promotions vorhanden</p>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default AppPromotions;