import { GifMedia } from "../files/gifMedia";
import { AppUser } from "../user/appUser";
import { Reaction } from "./reaction";

class Comment {
    constructor({
        id,
        comment,
        author,
        created,
        gifMedia,
        answerCount = 0,
        answers = [],
        deleted = false,
        isAnswer = false,
        reactions = [],
        replies = [],
        user,
    }) {
        try {
            this.id = id;
            this.comment = comment;
            this.author = author;
            this.created =  this.parseDate(created);
            this.answerCount = answerCount;
            this.answers = answers;
            this.gifMedia = gifMedia ? new GifMedia(gifMedia) : null;
            this.deleted = deleted;
            this.isAnswer = isAnswer;
            this.reactions = this.validateReactions(reactions);
            this.replies = Array.isArray(replies) ? replies.map((reply, index) => {
                try {
                    if (reply && reply.id) {
                        return new Comment(reply);
                    } else {
                        throw new Error(`Invalid reply data: Missing 'id' in reply at index ${index}`);
                    }
                } catch (error) {
                    return null;
                }
            }) : [];

            this.user = user instanceof AppUser ? user : new AppUser(user);
        } catch (error) {
        }
    }

    validateReactions(reactions) {
        try {
            if (!Array.isArray(reactions)) {
                throw new Error("Reactions must be an array.");
            }
            return reactions.map(reaction => new Reaction(reaction));
        } catch (error) {
            return [];
        }
    }

    parseDate(date) {
        try {
            if (!date) {
                return null;
            }

            if (typeof date._seconds === "number" && typeof date._nanoseconds === "number") {
                return new Date(date._seconds * 1000 + date._nanoseconds / 1000000);
            }

            const parsedDate = new Date(date);
            if (!isNaN(parsedDate)) {
                return parsedDate;
            }

            throw new Error(`Invalid date format: ${JSON.stringify(date)}`);
        } catch (error) {
            return null;
        }
    }

    static fromJson(json) {
        const parseDate = (date) => {
            if (!date) {
                return null;
            }
            if (typeof date._seconds === "number" && typeof date._nanoseconds === "number") {
                return new Date(date._seconds * 1000 + date._nanoseconds / 1000000);
            }
            const parsedDate = new Date(date);
            if (!isNaN(parsedDate)) {
                return parsedDate;
            }
            throw new Error(`Invalid date format: ${JSON.stringify(date)}`);
        };

        try {
            return new Comment({
                ...json,
                created: parseDate(json.created),
                updated: parseDate(json.updated),
                reactions: json.reactions ? json.reactions.map(reaction => Reaction.fromJson(reaction)) : [],
                replies: json.replies ? json.replies.map((reply, index) => {
                    try {
                        return Comment.fromJson(reply);
                    } catch (error) {
                        return null;
                    }
                }) : [],
                user: new AppUser(json.user),
            });
        } catch (error) {
            return null;
        }
    }
}

export default Comment;