import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import { Alert, Button, Card, Checkbox, cn, DatePicker, Select, SelectItem, Spacer, useDisclosure } from "@heroui/react";
import { FeedPostLabelEnum } from "@/enums/feed";
import userService from "@/services/userService";
import { v4 as uuidv4 } from 'uuid';
import { processFiles } from "@/helpers/fileHelper";
import { uploadFileToFirebase } from "@/helpers/storageHelper";
import Editor from "@/components/editor/Editor";
import { useNavigate } from "react-router-dom";
import feedService from "@/services/feedService";
import useToast from '@/hooks/useToast';
import { I18nProvider } from "@react-aria/i18n";
import { getLocalTimeZone, now, parseAbsoluteToLocal, today } from "@internationalized/date";

export default function FeedCreate() {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const [secret, setSecret] = React.useState(false);
    const [planned, setPlanned] = React.useState(false);
    const [editorContent, setEditorContent] = React.useState('');
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [label, setLabel] = React.useState(['allgemein']);
    const [labelError, setLabelError] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const onDrop = React.useCallback((acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter(file => file.size <= 50 * 1024 * 1024);
        const uniqueFiles = filteredFiles.filter((file) =>
            !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name)
        );
        setUploadedFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
    }, [uploadedFiles]);

    const handleRemoveFile = (fileName) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const handleEditorChange = (content) => {
        setEditorContent(content);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '*/*',
        maxSize: 50 * 1024 * 1024
    });

    const handlePost = async () => {
        setUploading(true);

        if (!label || label.isEmpty) {
            setLabelError(true);
            setUploading(false);
            showToast('Bitte hinterlege eine Gruppe.', 'error');
            return;
        }

        let text = '';
        if (editorContent && editorContent.ops && editorContent.ops.length > 0) {
            editorContent.ops.forEach(op => {
                if (op.insert) {
                    text += op.insert;
                }
            });
        }

        if (text.length < 1 && uploadedFiles.length === 0) {
            setUploading(false);
            showToast('Bitte füge Text oder Dateien hinzu.', 'error');
            return;
        }

        let processedFiles = null;
        if (uploadedFiles.length > 0) {
            processedFiles = await processFiles(uploadedFiles);
        }

        const imageMedia = [];
        const videoMedia = [];
        const fileMedia = [];
        const voiceMedia = [];

        const postId = uuidv4();

        if (processedFiles) {
            for (const image of processedFiles.images) {
                const imageUrl = await uploadFileToFirebase(image, `internal/posts/${postId}/images/`);
                imageMedia.push(imageUrl);
            }

            for (const video of processedFiles.videos) {
                const videoUrl = await uploadFileToFirebase(video.url, `internal/posts/${postId}/videos/`);
                const thumbnailUrl = await uploadFileToFirebase(video.thumbnail, `internal/posts/${postId}/thumbnails/`);
                videoMedia.push({
                    url: videoUrl,
                    thumbnail: thumbnailUrl,
                    duration: video.duration,
                    aspectRatio: video.aspectRatio,
                });
            }

            for (const audio of processedFiles.audios) {
                const audioUrl = await uploadFileToFirebase(audio.url, `internal/posts/${postId}/voices/`);
                voiceMedia.push({
                    url: audioUrl,
                    duration: audio.duration,
                });
            }

            for (const file of processedFiles.others) {
                const fileUrl = await uploadFileToFirebase(file.url, `internal/posts/${postId}/files/`);
                fileMedia.push({
                    filename: file.filename,
                    url: fileUrl,
                    size: file.size,
                    filetype: file.filetype,
                });
            }
        }

        const post = {
            id: postId,
            label: label,
            groups: [label],
            sharing: true,
            textContent: JSON.stringify(editorContent.ops),
            imageMedia: imageMedia,
            videoMedia: videoMedia,
            fileMedia: fileMedia,
            voiceMedia: voiceMedia,
        };

        try {
            const result = await feedService.createPost(JSON.stringify(post));

            if (result === true) {
                showToast('Post erfolgreich erstellt.', 'success');
                handleClose();
            } else {
                showToast('Post nicht erstellt.', 'error');
            }
        } catch (error) {
            showToast('Post nicht erstellt.', 'error');
        } finally {
            setUploading(false);
        }
    };

    const handlePreview = () => {
        onOpen();
    };

    const handleClose = useCallback(() => {
        navigate('/feed');
    }, [navigate]);

    return (
        <div className="w-full flex-1 p-4">
            <Icon
                className="text-default-400 absolute top-4 right-4 cursor-pointer"
                icon="solar:close-square-bold"
                width={28}
                height={28}
                onClick={handleClose}
            />

            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">
                    Post erstellen
                </h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Poste einen Beitrag für den Feed.
            </h2>

            <div className="mt-4 flex flex-col lg:flex-row gap-3">
                <div className="w-full lg:w-2/3">
                    <Editor value={editorContent} onChange={handleEditorChange}></Editor>
                </div>
                <div className="w-full lg:w-1/3">
                    <Card className="rounded-lg border bg-background shadow p-2">
                        <div {...getRootProps({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 p-4 rounded-lg text-center cursor-pointer">
                            <input {...getInputProps()} />
                            <p className="text-gray-500">
                                Dateien reinziehen oder per Klick hochladen
                            </p>
                            <p className="text-xs text-gray-400">
                                (Alle Dateitypen erlaubt, max. 50 MB pro Datei)
                            </p>
                        </div>
                        {uploadedFiles.length > 0 && (
                            <div className="mt-4">
                                <h3 className="text-small text-default-500 mb-2">Anhänge:</h3>
                                <ul>
                                    {uploadedFiles.map((file, index) => (
                                        <li key={index} className="flex justify-between items-center text-small text-default-400">
                                            {file.name} ({(file.size / 1024).toFixed(2)} KB)
                                            <Icon
                                                className="text-default-400 cursor-pointer"
                                                icon="mdi:close"
                                                width={20}
                                                height={20}
                                                onClick={() => handleRemoveFile(file.name)}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <Spacer y={4} />
                        <Select
                            variant="bordered"
                            label="Gruppe wählen"
                            radius="lg"
                            size="sm"
                            selectedKey={label}
                            defaultSelectedKeys={['allgemein']}
                            onChange={(e) => {
                                setLabel(e.target.value);
                                setLabelError(false)
                            }}
                            isInvalid={labelError}
                        >
                            {Object.values(FeedPostLabelEnum).map((label) => (
                                <SelectItem key={label.label}>
                                    {`${label.emoji} ${label.name}`}
                                </SelectItem>
                            ))}
                        </Select>
                        <Spacer y={4} />
                        <div className="flex items-center gap-4 ml-1">
                            <label className="flex items-center gap-4 cursor-pointer">
                                <Checkbox
                                    aria-label="planned"
                                    isSelected={planned}
                                    onValueChange={setPlanned}
                                />
                                <div>
                                    <p className="text-small text-default-500">
                                        <strong>Post einplanen</strong>
                                    </p>
                                    <p className="mt-1 text-small text-default-500">
                                        Veröffentliche den Post erst zu einem bestimmten Zeitpunkt.
                                    </p>
                                </div>
                            </label>
                            <div className="mt-2">
                                <I18nProvider locale="de">
                                    <DatePicker
                                        className="col-span-12"
                                        variant="bordered"
                                        granularity="minute"
                                        visibleMonths={2}
                                        isDisabled={!planned}
                                    />
                                </I18nProvider>
                            </div>
                        </div>
                        <Spacer y={4} />
                        <Alert description="Post schützen, Post einplanen und Entwurf speichern sind derzeit nicht verfügbar." title="Teils nicht verfügbar" />
                        <Spacer y={4} />
                        <div className="flex space-x-1">
                            <Button fullWidth={true} isLoading={uploading} color="secondary">
                                Entwurf speichern
                            </Button>
                            <Button fullWidth={true} isLoading={uploading} color="primary" onPress={handlePost}>
                                Beitrag posten
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
