import React, { useState, useEffect, useRef } from 'react';
import feedService from '../services/feedService';
import CommentComponent from './Comment';
import { Spinner, Image, Button, Textarea, ScrollShadow } from "@heroui/react";
import emptyZoxsy from '../assets/svg/zoxsy_legal.svg';
import commentService from '../services/commentService';
import { Icon } from '@iconify/react/dist/iconify.js';
import useToast from '@/hooks/useToast';

function CommentSection({ id, updateCommentCount }) {
    const { showToast } = useToast();
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newComment, setNewComment] = useState("");
    const commentsContainerRef = useRef(null);

    const scrollToBottom = () => {
        if (commentsContainerRef.current) {
            commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
    };

    const addComment = async (reply = null, commentId = null, replyId = null, replyAuthorId = null) => {
        const commentContent = typeof reply === "string" && reply.trim() ? reply : newComment;

        if (commentContent.trim().length < 1) {
            showToast('Bitte gib einen Kommentar ein', 'error');
            return;
        }

        try {
            const addedComment = await feedService.postComment(
                id,
                commentContent,
                commentId || null,
                replyId || null,
                replyId
                    ? comments
                        .flatMap((c) => (c.id === commentId ? c.replies : []))
                        .find((r) => r.id === replyId)?.user.id
                    : null,
                replyAuthorId || null
            );

            if (addedComment) {
                updateCommentCount(1);

                setComments((prevComments) => {
                    if (commentId) {
                        return prevComments.map((comment) =>
                            comment.id === commentId
                                ? {
                                    ...comment,
                                    replies: [...(comment.replies || []), addedComment].sort(
                                        (a, b) => a.created._seconds - b.created._seconds
                                    ),
                                }
                                : comment
                        );
                    } else {
                        return [...prevComments, addedComment].sort(
                            (a, b) => a.created._seconds - b.created._seconds
                        );
                    }
                });

                setNewComment('');
                scrollToBottom();
            }
        } catch (error) {
            showToast('Kommentar konnte nicht hinzugefügt werden.', 'error');
        }
    };

    const editComment = async (comment = null, commentId = null, replyId = null) => {
        try {
            const editedComment = await feedService.editComment(id, comment, commentId, replyId);
    
            if (editedComment) {
                setComments((prevComments) => {
                    return prevComments.map((item) => {
                        if (item.id === commentId) {
                            if (replyId) {
                                return {
                                    ...item,
                                    replies: item.replies.map((reply) =>
                                        reply.id === replyId
                                            ? { ...reply, comment: comment }
                                            : reply
                                    ),
                                };
                            }
                            return {
                                ...item,
                                comment: comment,
                            };
                        }
                        return item;
                    });
                });
    
                showToast('Kommentar erfolgreich bearbeitet.', 'success');
            }
        } catch (error) {
            showToast('Kommentar konnte nicht bearbeitet werden.', 'error');
        }
    };

    const deleteComment = async (commentId, replyId = null) => {
        try {
            const deletedComment = await feedService.deleteComment(id, commentId, replyId);

            if (deletedComment) {
                setComments((prevComments) =>
                    prevComments.map((comment) => {
                        if (comment.id === commentId) {
                            if (replyId) {
                                return {
                                    ...comment,
                                    replies: comment.replies.filter((reply) => reply.id !== replyId),
                                };
                            }
                            return null;
                        }
                        return comment;
                    }).filter((comment) => comment !== null)
                );

                const countChange = replyId ? -1 : -(1 + (deletedComment.replies?.length || 0));
                updateCommentCount(countChange);
            }
        } catch (error) {
            showToast('Kommentar konnte nicht gelöscht werden.', 'error');
        }
    };

    const handleReply = (commentId, replyId = null, replyText = "", replyAuthorId = null) => {
        if (replyText.trim().length < 1) {
            showToast('Bitte gib eine Antwort ein', 'error');
            return;
        }
        addComment(replyText, commentId, replyId, replyAuthorId);
    };

    const handleEdit = (comment, commentId, replyId) => {
        if (comment.trim().length < 1) {
            showToast('Bitte gib einen Text ein', 'error');
            return;
        }
        editComment(comment, commentId, replyId);
    };

    const handleInput = (event) => {
        setNewComment(event.target.value);
    };

    const fetchComments = async () => {
        try {
            const commentData = await feedService.getCommentsForPost(id);
            setComments(commentData.comments);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleReact = async (postId, commentId, replyId, reactionId) => {
        try {
            const updatedReactions = await feedService.postReaction(postId, commentId, replyId, reactionId);

            setComments((prevComments) =>
                prevComments.map((comment) => {
                    if (comment.id === commentId) {
                        if (replyId) {
                            const updatedReplies = comment.replies.map((reply) =>
                                reply.id === replyId
                                    ? { ...reply, reactions: updatedReactions }
                                    : reply
                            );
                            return { ...comment, replies: updatedReplies };
                        }
                        return { ...comment, reactions: updatedReactions };
                    }
                    return comment;
                })
            );
            return updatedReactions;
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchComments();
    }, [id]);

    useEffect(() => {
        scrollToBottom();
    }, [comments]);

    return (
        <div className="flex flex-col h-full">
            {loading ? (
                <div className="flex items-center justify-center flex-1">
                    <Spinner />
                </div>
            ) : (
                <>
                    <ScrollShadow>
                        <div className="flex-1 overflow-y-auto pr-2" ref={commentsContainerRef}>
                            {comments.length === 0 ? (
                                <div className="flex items-center justify-center flex-col h-full">
                                    <Image
                                        width={150}
                                        alt="Keine Kommentare ZOXSY"
                                        src={emptyZoxsy}
                                    />
                                    <h4 className="mt-4 text-sm font-semibold text-gray-600">
                                        Keine Kommentare!
                                    </h4>
                                    <h5 className="mt-2 mb-4 text-sm text-gray-400">
                                        Schreibe den ersten Kommentar!
                                    </h5>
                                </div>
                            ) : (
                                comments.map((comment) => (
                                    <div className="mb-2" key={comment.id}>
                                        <CommentComponent comment={comment} postId={id} onReact={handleReact} onReply={handleReply} onDelete={deleteComment} onEdit={handleEdit} />
                                    </div>
                                ))
                            )}
                        </div>
                    </ScrollShadow>
                    <div className="mt-auto flex flex-col sticky bottom-0 bg-white pt-2 pr-2 z-50">
                        <div className="flex items-end gap-2">
                            <Textarea
                                type="text"
                                size="sm"
                                radius="md"
                                placeholder="Was denkst Du dazu...?"
                                name="text"
                                minRows={1}
                                rows={1}
                                maxRows={5}
                                variant="bordered"
                                className="w-full"
                                value={newComment}
                                onChange={handleInput}
                            />
                            <Button
                                isIconOnly
                                color="primary"
                                variant="solid"
                                aria-label="Senden"
                                size="md"
                                radius="full"
                                onPress={addComment}
                            >
                                <Icon className="text-white" icon="solar:plain-3-outline" width={20} />
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default CommentSection;