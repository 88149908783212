import React from 'react';
import { Tooltip, Button, Popover, PopoverTrigger, PopoverContent } from "@heroui/react";
import { Icon } from '@iconify/react/dist/iconify.js';
import { emojiMap } from '@/helpers/emojiMap';

const ReactionButton = ({ reaction, onReact }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Tooltip showArrow content={reaction ? "Deine Reaktion" : "Reagieren"}>
            <Popover showArrow offset={10} placement="bottom" backdrop="blur" isOpen={isOpen} onOpenChange={(open) => setIsOpen(open)}>
                <PopoverTrigger>
                    <Button
                        isIconOnly
                        color="primary"
                        variant="light"
                        aria-label={reaction ? "Deine Reaktion" : "Reagieren"}
                        size="sm"
                    >
                        {reaction ? (
                            <span className="text-default-400" style={{ fontSize: '20px' }}>
                                {reaction.emoji}
                            </span>
                        ) : (
                            <Icon
                                className="text-default-400"
                                icon="solar:sticker-smile-circle-2-outline"
                                width={20}
                            />
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="p-2">
                    <div className="grid grid-cols-4 gap-2">
                        {Object.entries(emojiMap).map(([id, { emoji, name }]) => {
                            const isCurrentReaction = reaction && reaction.emoji === emoji;
                            return (
                                <Tooltip key={id} showArrow content={name}>
                                    <Button
                                        isIconOnly
                                        aria-label={name}
                                        size="lg"
                                        radius="full"
                                        color="primary"
                                        variant={isCurrentReaction ? "faded" : "light"}
                                        onClick={() => {
                                            onReact(id);
                                            setIsOpen(false);
                                        }}
                                        className="flex flex-col items-center"
                                    >
                                        <span style={{ fontSize: '20px' }}>{emoji}</span>
                                    </Button>
                                </Tooltip>
                            );
                        })}
                    </div>
                </PopoverContent>
            </Popover>
        </Tooltip>
    );
};

export default ReactionButton;