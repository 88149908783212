"use client";

import React from "react";
import { Button } from "@heroui/react";
import { Icon } from "@iconify/react";
import { cn } from "@heroui/react";
import AppPushCreatorNavigationButtons from "./AppPushCreatorNavigationButtons";
import AppPushCreatorRowSteps from "./AppPushCreatorRowSteps";
import AppPushCreatorVerticalSteps from "./AppPushCreatorVerticalSteps";
import { useNavigate } from "react-router-dom";

const stepperClasses = cn(
    "[--step-color:#d30f1e]",
    "[--active-color:#d30f1e]",
);

const AppPushCreatorNavigation = React.forwardRef(
    ({ children, className, currentPage, onBack, onNext, onChangePage, ...props }, ref) => {
        const navigate = useNavigate();
        const handleClose = () => {
            navigate('/admin-push');
        };
        return (
            <div
                ref={ref}
                className={cn("flex h-screen w-full gap-x-2", className)}
                {...props}
            >
                <div className="flex hidden h-full w-80 flex-shrink-0 flex-col items-start gap-y-8 px-8 py-6 shadow-small lg:flex">
                    <Button
                        className="bg-default-50 text-small font-medium text-default-500 shadow-lg"
                        radius="full"
                        variant="flat"
                        onPress={currentPage === 0 ? handleClose : onBack}
                    >
                        <Icon icon="solar:arrow-left-outline" width={18} />
                        Zurück
                    </Button>
                    <div>
                        <div className="text-xl font-medium leading-7 text-default-foreground">
                            Push-Kampagne erstellen
                        </div>
                        <div className="mt-1 text-base font-medium leading-6 text-default-500">
                            Erreiche App-Nutzer durch gezielte und personalisierte Mitteilungen.
                        </div>
                    </div>
                    {/* Desktop Steps */}
                    <AppPushCreatorVerticalSteps
                        className={stepperClasses}
                        color="secondary"
                        currentStep={currentPage}
                        steps={[
                            {
                                title: "Inhalt",
                            },
                            {
                                title: "Zielgruppe",
                            },
                            {
                                title: "Push-Ziel",
                            },
                            {
                                title: "Terminierung",
                            },
                            {
                                title: "Übersicht",
                            },
                        ]}
                        onStepChange={onChangePage}
                    />
                </div>
                <div className="flex h-full w-full flex-col items-center gap-4">
                    <div className="sticky top-0 z-10 w-full rounded-large bg-gradient-to-r from-default-100 via-danger-100 to-secondary-100 py-4 shadow-small md:max-w-xl lg:hidden">
                        <div className="flex justify-center">
                            <AppPushCreatorRowSteps
                                className={cn("pl-6", stepperClasses)}
                                currentStep={currentPage}
                                steps={[
                                    {
                                        title: "Inhalt",
                                    },
                                    {
                                        title: "Zielgruppe",
                                    },
                                    {
                                        title: "Push-Ziel",
                                    },
                                    {
                                        title: "Terminierung",
                                    },
                                    {
                                        title: "Übersicht",
                                    },
                                ]}
                                onStepChange={onChangePage}
                            />
                        </div>
                    </div>
                    <div className="h-full w-full flex flex-col overflow-hidden">
                        <div className="flex-grow overflow-auto">
                            <div className="p-4 sm:max-w-md md:max-w-lg mx-auto">
                                {children}
                            </div>
                        </div>
                        <AppPushCreatorNavigationButtons
                            backButtonProps={{ isDisabled: currentPage === 0 }}
                            className="lg:hidden"
                            nextButtonProps={{
                                children:
                                    currentPage === 4
                                        ? "Push-Kampagne starten"
                                        : "Weiter",
                            }}
                            onBack={onBack}
                            onNext={onNext}
                        />
                    </div>

                </div>
            </div>
        );
    },
);

AppPushCreatorNavigation.displayName = "AppPushCreatorNavigation";

export default AppPushCreatorNavigation;
