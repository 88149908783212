import React, { useEffect, useState } from 'react';
import {
    Card, CardFooter, Image, Button, Spinner
} from "@heroui/react";
import { useNavigate } from 'react-router-dom';
import wikiService from '@/services/wikiService';

const WikiHome = () => {
    const [wikis, setWikis] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWikis = async () => {
            try {
                const { wikiData } = await wikiService.getWikiCollections();
                setWikis(wikiData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWikis();
    }, []);

    const handleWikiClick = (wiki) => {
        navigate(`/wiki/${wiki.id}`);
    };

    return (
        <div className="w-full flex-1 p-4">
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Wiki</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">Deine Knowledge-Base für Deinen Alltag im ZOXS-Universum.</h2>

            {loading && (
                <div className="flex justify-center my-4">
                    <Spinner size="lg" />
                </div>
            )}

            {!loading && error && (
                <div className="flex justify-center my-4">
                    <p className="text-red-500">Error: {error}</p>
                </div>
            )}

            {!loading && !error && (
                <div className="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-3">
                    {wikis.map((wiki) => (
                        <div key={wiki.id} className="flex justify-center">
                            <Card
                                isFooterBlurred
                                radius="none"
                                className="w-full h-[300px] rounded-lg border bg-background shadow"
                                isPressable
                                onClick={() => handleWikiClick(wiki)}
                            >
                                <Image
                                    removeWrapper
                                    radius="none"
                                    alt={wiki.title}
                                    className="z-0 w-full h-full object-cover"
                                    src={wiki.img || 'https://via.placeholder.com/350'}
                                />
                                <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t border-default-600">
                                    <div className="flex flex-grow gap-2 items-center">
                                        <div className="flex flex-col">
                                            <p className="text-white/90 font-medium text-l">{wiki.title}</p>
                                        </div>
                                    </div>
                                    <Button
                                        color="primary"
                                        radius="full"
                                        size="sm"
                                        onClick={() => handleWikiClick(wiki)}
                                    >
                                        Aufrufen
                                    </Button>
                                </CardFooter>
                            </Card>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default WikiHome;
