import useToast from '@/hooks/useToast';
import { Icon } from '@iconify/react/dist/iconify.js';
import { getLocalTimeZone, parseAbsoluteToLocal, parseDate, today } from '@internationalized/date';
import { Autocomplete, AutocompleteItem, Button, Checkbox, DateRangePicker, Divider, Input, Spacer, Spinner, Textarea, Tooltip } from "@heroui/react";
import { I18nProvider } from '@react-aria/i18n';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import { Timestamp } from 'firebase/firestore';
import AppPromoImageUpload from '@/components/AppPromoImageUpload';
import zoxsManagementService from '@/services/zoxsManagementService';

const AppFeedEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const isEditing = location.pathname === '/admin-feed/edit';
  const [imgUrl, setImgUrl] = useState(null);

  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);

  const [isSaving, setIsSaving] = useState(false);
  const [typeVal, setTypeVal] = useState(null);
  const [isProMode, setIsProMode] = React.useState(false);

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [buttonColor, setButtonColor] = useState('#D3101E');
  const [buttonTextColor, setButtonTextColor] = useState('#FFFFFF');
  const [buttonText, setButtonText] = useState('Mehr erfahren');
  const [route, setRoute] = useState('');
  const [args, setArgs] = useState('');
  const [order, setOrder] = useState('');
  const [url, setUrl] = useState('');

  const [typeError, setTypeError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [textError, setTextError] = useState(false);
  const [buttonColorError, setButtonColorError] = useState(false);
  const [buttonTextColorError, setButtonTextColorError] = useState(false);
  const [buttonTextError, setButtonTextError] = useState(false);
  const [routeError, setRouteError] = useState(false);
  const [argsError, setArgsError] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [urlError, setUrlError] = useState(false);

  const [time, setTime] = React.useState(() => {
    const today = new Date();
    const start = today;
    const end = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());

    return {
      start: parseAbsoluteToLocal(start.toISOString()),
      end: parseAbsoluteToLocal(end.toISOString()),
    };
  });

  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageChange = (file) => {
    setUploadedImage(file);
  };

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      try {
        if (isEditing && id) {
          await fetchFeedArticle(id);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, [isEditing, id]);

  const fetchFeedArticle = async (id) => {
    try {
      const response = await zoxsManagementService.getFeedElementById(id);
      if (response && response.element) {
        const {
          type,
          title,
          text,
          start,
          end,
          buttonColor,
          buttonTextColor,
          buttonText,
          order,
          route,
          args,
          imgUrl
        } = response.element;

        setImgUrl(imgUrl);
        setTypeVal(type);
        setTitle(title);
        setText(text);
        setTime({
          start: parseAbsoluteToLocal(new Date(start).toISOString()),
          end: parseAbsoluteToLocal(new Date(end).toISOString()),
        });
        setButtonColor(buttonColor);
        setButtonTextColor(buttonTextColor);
        setButtonText(buttonText);
        setOrder(order);
        setRoute(route);
        setArgs(args ? JSON.stringify(args) : '');
        setUploadedImage(imgUrl);
        setIsProMode(true);
      } else {
        showToast('Feed-Element konnte nicht geladen werden.', 'error');
      }
    } catch (error) {
      showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
    }
  };

  const generateJson = () => {
    const startDate = new Date(
      time.start.year,
      time.start.month - 1,
      time.start.day,
      time.start.hour,
      time.start.minute,
      time.start.second,
      time.start.millisecond
    );

    const endDate = new Date(
      time.end.year,
      time.end.month - 1,
      time.end.day,
      time.end.hour,
      time.end.minute,
      time.end.second,
      time.end.millisecond
    );

    const adjustedRoute = !isProMode ? '/customTab' : route || null;
    let adjustedArgs;
    if (isProMode) {
      try {
        adjustedArgs = JSON.parse(args) || { url };
      } catch (error) {
        adjustedArgs = {};
      }
    } else {
      adjustedArgs = { url };
    }

    return {
      id: id || null,
      type: typeVal || null,
      title: title || null,
      text: text || null,
      start: startDate,
      end: endDate,
      buttonColor: buttonColor || null,
      buttonTextColor: buttonTextColor || null,
      buttonText: buttonText || null,
      order: order ? parseInt(order, 10) : null,
      route: adjustedRoute,
      args: JSON.stringify(adjustedArgs),
    };
  };

  const handleSubmit = async () => {
    let valid = true;

    const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;
    if (!hexColorRegex.test(buttonColor)) {
      setButtonColorError(true);
      valid = false;
    }

    if (!hexColorRegex.test(buttonTextColor)) {
      setButtonTextColorError(true);
      valid = false;
    }

    if (title.length < 4) {
      setTitleError(true);
      valid = false;
    }

    if (text.length < 20) {
      setTextError(true);
      valid = false;
    }

    if (!typeVal) {
      setTypeError(true);
      valid = false;
    }

    if (order !== null && !Number.isInteger(parseInt(order, 10))) {
      setOrderError(true);
      valid = false;
    }

    const now = new Date();
    const startDate = new Date(Date.UTC(
      time.start.year,
      time.start.month - 1,
      time.start.day,
      time.start.hour,
      time.start.minute,
      time.start.second,
      time.start.millisecond
    ));

    const endDate = new Date(Date.UTC(
      time.end.year,
      time.end.month - 1,
      time.end.day,
      time.end.hour,
      time.end.minute,
      time.end.second,
      time.end.millisecond
    ));

    if (endDate < now || endDate < startDate) {
      showToast('Bitte wähle ein gültiges Enddatum.', 'error');
      valid = false;
    }

    if (!uploadedImage) {
      showToast('Bitte lade ein Bild hoch.', 'error');
      valid = false;
    }

    if (!isProMode) {
      try {
        new URL(url);
      } catch (_) {
        setUrlError(true);
        showToast('Bitte eine gültige URL eingeben.', 'error');
        valid = false;
      }
    }

    if (isProMode) {
      if (!route) {
        setRouteError(true);
        valid = false;
      }
      try {
        const adjustedArgs = JSON.parse(args) || { url };
      } catch (error) {
        showToast('Die eingegebenen Arguments können nicht geparsed werden.', 'error');
        valid = false;
      }
    }

    if (!valid) {
      return;
    }

    const jsonBody = generateJson();

    try {
      setIsSaving(true);
      const formData = new FormData();
      formData.append("image", uploadedImage);
      Object.keys(jsonBody).forEach((key) => formData.append(key, jsonBody[key]));
      if (isEditing) {
        await zoxsManagementService.updateFeedElement(jsonBody);
        showToast('Feed-Element erfolgreich bearbeitet.', 'success');
        navigate('/admin-feed');
      } else {
        await zoxsManagementService.createFeedElement(formData);
        showToast('Feed-Element erfolgreich erstellt.', 'success');
        navigate('/admin-feed');
      }
    } catch (error) {
      showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    navigate('/admin-feed');
  };

  return (
    <div className="w-full flex-1 p-4">
        <Icon
          className="text-default-400 absolute top-4 right-4 cursor-pointer z-50"
          icon="solar:close-square-bold"
          width={28}
          height={28}
          onClick={handleClose}
        />

      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">
          {isEditing ? 'Feed-Element bearbeiten' : 'Feed-Element erstellen'}
        </h1>
      </div>
      <h2 className="mt-2 text-small text-default-500 mb-6">
        Erstelle oder bearbeite Feed-Elemente.
      </h2>

      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-4 flex flex-col space-y-4">
          <h4 className="text-medium font-medium">Feed-Element Daten</h4>
          {isEditing ? <div className="w-full flex justify-center">
            <Tooltip showArrow content="Bilder können nicht mehr geändert werden.">
              <div
                className="relative border border-gray-300 rounded-lg overflow-hidden max-w-xl"
                style={{ aspectRatio: '16 / 9' }}
              >
                <img src={imgUrl} alt="Uploaded" className="w-full h-full object-cover" />
              </div>
            </Tooltip>
          </div> : <AppPromoImageUpload onImageChange={handleImageChange} />}
          <div className="flex justify-between items-center">
            <Autocomplete
              variant="bordered"
              label="Typ"
              isDisabled={isEditing}
              isLoading={loading}
              size='md'
              placeholder='Bitte auswählen'
              selectedKey={typeVal}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:layers-minimalistic-outline"
                  width={18}
                />
              }
              onSelectionChange={setTypeVal}
              listboxProps={{
                emptyContent: 'Keine Typen.'
              }}
            >
              <AutocompleteItem key="zoxs">ZOXS</AutocompleteItem>
              <AutocompleteItem key="zoxs-service">ZOXS-Service</AutocompleteItem>
              <AutocompleteItem key="buyzoxs">buyZOXS</AutocompleteItem>
              <AutocompleteItem key="buyzoxs-service">buyZOXS-Service</AutocompleteItem>
            </Autocomplete>
            <Spacer x={2} />
            <Input
              variant="bordered"
              type="text"
              size='md'
              isDisabled={isEditing}
              label="Reihenfolge"
              value={order}
              defaultValue=""
              labelPlacement="inside"
              isInvalid={orderError}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:sort-vertical-outline"
                  width={18}
                />
              }
              onChange={(e) => {
                setOrder(e.target.value);
                setOrderError(false);
              }}
            />
          </div>

          <div className="flex justify-between items-center">
            <Input
              variant="bordered"
              type="text"
              size='md'
              label="Titel"
              value={title}
              defaultValue=""
              labelPlacement="inside"
              isInvalid={titleError}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:text-square-outline"
                  width={18}
                />
              }
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError(false);
              }}
            />
            <Spacer x={2} />
            <I18nProvider locale="de">
              <DateRangePicker
                label="Zeitraum"
                value={time}
                variant='bordered'
                onChange={setTime}
                visibleMonths={3}
                startContent={
                  <Icon
                    className="text-default-400 pointer-events-none"
                    height={18}
                    icon="solar:calendar-date-outline"
                    width={18}
                  />
                }
              />
            </I18nProvider>
          </div>
          <Textarea
            variant="bordered"
            type="text"
            size='md'
            label="Beschreibung"
            value={text}
            defaultValue=""
            labelPlacement="inside"
            isInvalid={textError}
            startContent={
              <Icon
                className="text-default-400 pointer-events-none"
                height={18}
                icon="solar:document-text-outline"
                width={18}
              />
            }
            onChange={(e) => {
              setText(e.target.value);
              setTextError(false);
            }}
          />
          <div className="flex justify-between items-center">
            <Input
              variant="bordered"
              type="text"
              size='md'
              label="Buttonfarbe"
              value={buttonColor}
              defaultValue=""
              labelPlacement="inside"
              isInvalid={buttonColorError}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:paint-roller-outline"
                  width={18}
                />
              }
              onChange={(e) => {
                setButtonColor(e.target.value);
                setButtonColorError(false);
              }}
            />
            <Spacer x={2} />
            <Input
              variant="bordered"
              type="text"
              size='md'
              label="Button Textfarbe"
              value={buttonTextColor}
              defaultValue=""
              labelPlacement="inside"
              isInvalid={buttonTextColorError}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:paint-roller-outline"
                  width={18}
                />
              }
              onChange={(e) => {
                setButtonTextColor(e.target.value);
                setButtonTextColorError(false);
              }}
            />
            <Spacer x={2} />
            <Input
              variant="bordered"
              type="text"
              size='md'
              label="Buttontext"
              value={buttonText}
              defaultValue=""
              labelPlacement="inside"
              isInvalid={buttonTextError}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:text-square-outline"
                  width={18}
                />
              }
              onChange={(e) => {
                setButtonText(e.target.value);
                setButtonTextError(false);
              }}
            />
          </div>
          <div className="flex items-center gap-4">
            <Checkbox isSelected={isProMode} onValueChange={setIsProMode} className="flex-shrink-0">
              Ziel manuell setzen
            </Checkbox>
            <div className="flex items-center w-full">
              {!isProMode ? (
                <Input
                  variant="bordered"
                  type="text"
                  size="md"
                  label="URL"
                  value={url}
                  defaultValue=""
                  labelPlacement="inside"
                  isInvalid={urlError}
                  startContent={
                    <Icon
                      className="text-default-400 pointer-events-none"
                      height={18}
                      icon="solar:routing-2-outline"
                      width={18}
                    />
                  }
                  onChange={(e) => {
                    setUrl(e.target.value);
                    setUrlError(false);
                  }}
                />
              ) : (
                <>
                  <Input
                    variant="bordered"
                    type="text"
                    size="md"
                    label="Route"
                    value={route}
                    defaultValue=""
                    labelPlacement="inside"
                    isInvalid={routeError}
                    startContent={
                      <Icon
                        className="text-default-400 pointer-events-none"
                        height={18}
                        icon="solar:routing-2-outline"
                        width={18}
                      />
                    }
                    onChange={(e) => {
                      setRoute(e.target.value);
                      setRouteError(false);
                    }}
                  />
                  <Spacer x={2} />
                  <Input
                    variant="bordered"
                    type="text"
                    size="md"
                    label="Arguments (JSON)"
                    value={args}
                    defaultValue=""
                    labelPlacement="inside"
                    isInvalid={argsError}
                    startContent={
                      <Icon
                        className="text-default-400 pointer-events-none"
                        height={18}
                        icon="solar:code-bold"
                        width={18}
                      />
                    }
                    onChange={(e) => {
                      setArgs(e.target.value);
                      setArgsError(false);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              onPress={handleSubmit}
              isLoading={isSaving}
              color="primary"
              size="lg"
              className="py-1 px-3"
            >
              {isEditing ? 'Feed-Element bearbeiten' : 'Feed-Element erstellen'}
            </Button>
          </div>
          <Divider />
          <h4 className="text-medium font-medium">Dev-Details</h4>
          <div className="flex justify-between items-center">
            <JsonView src={generateJson()} />
          </div>
        </div>)
      }
    </div >
  );
};

export default AppFeedEditor;
