"use client";

import React from "react";
import { Icon } from "@iconify/react";
import { cn } from "@heroui/react";
import { countries, states } from "./types";
import { useAppPushCreator } from "./AppPushCreator";
import JsonView from "react18-json-view";

const AppPushCreatorOverview = React.forwardRef(({ className, ...props }, ref) => {
  const { state, updateField } = useAppPushCreator();

  return (
    <>
      <div className="text-3xl font-bold leading-9 text-default-foreground">Übersicht</div>
      <div className="py-4 text-base leading-5 text-default-500">Deine Push-Notification:</div>
      <JsonView src={state.json}/>
    </>
  );
});

AppPushCreatorOverview.displayName = "AppPushCreatorOverview";

export default AppPushCreatorOverview;
