import useToast from '@/hooks/useToast';
import { Icon } from '@iconify/react/dist/iconify.js';
import { parseAbsoluteToLocal, today } from '@internationalized/date';
import { Autocomplete, AutocompleteItem, Button, DateRangePicker, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, Spinner, Textarea, Tooltip, useDisclosure } from "@heroui/react";
import { I18nProvider } from '@react-aria/i18n';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import AppPromoImageUpload from '@/components/AppPromoImageUpload';
import zoxsManagementService from '@/services/zoxsManagementService';

const audienceOptions = [
    { label: 'Alle Nutzer', value: 'allUsers' },
    { label: 'Nur ausgeloggte Nutzer', value: 'loggedOutUsers' },
    { label: 'Nur eingeloggte Nutzer', value: 'loggedInUsers' },
    { label: 'Eingeloggt & mit Verkaufskorb', value: 'loggedInWithSellBasket' },
    { label: 'Eingeloggt & mit Warenkorb', value: 'loggedInWithShoppingCart' },
    { label: 'Benutzerdefiniert', value: 'custom' },
];

const typeOptions = {
    allUsers: [
        { label: 'Promotion', value: 'promotion' },
        { label: 'Liveshow', value: 'liveshow' },
        { label: 'Gewinnspiel', value: 'lottery' },
    ],
    loggedOutUsers: [
        { label: 'Promotion', value: 'promotion' },
        { label: 'Liveshow', value: 'liveshow' },
        { label: 'Gewinnspiel', value: 'lottery' },
    ],
    loggedInUsers: [
        { label: 'Promotion', value: 'promotion' },
        { label: 'Liveshow', value: 'liveshow' },
        { label: 'Gewinnspiel', value: 'lottery' },
    ],
    loggedInWithSellBasket: [
        { label: 'Promotion', value: 'promotion' },
        { label: 'Liveshow', value: 'liveshow' },
        { label: 'Gewinnspiel', value: 'lottery' },
        { label: 'Verkaufskorb-Reminder', value: 'sellBasketReminder' },
    ],
    loggedInWithShoppingCart: [
        { label: 'Promotion', value: 'promotion' },
        { label: 'Liveshow', value: 'liveshow' },
        { label: 'Gewinnspiel', value: 'lottery' },
        { label: 'Warenkorb-Reminder', value: 'buyBasketReminder' },
    ],
    custom: [
        { label: 'Promotion', value: 'promotion' },
        { label: 'Ausgewählte Promotion', value: 'selectedPromotion' },
        { label: 'Verkaufskorb-Reminder', value: 'sellBasketReminder' },
        { label: 'Warenkorb-Reminder', value: 'buyBasketReminder' },
        { label: 'Verkaufsauftrag', value: 'sellOrder' },
        { label: 'Bestellung', value: 'buyOrder' },
        { label: 'Preisalarm', value: 'priceAlert' },
        { label: 'Wieder auf Lager', value: 'backInStock' },
        { label: 'Verkauf möglich', value: 'readyForSell' },
        { label: 'Ankaufanfrage', value: 'articleRequest' },
        { label: 'Liveshow', value: 'liveshow' },
        { label: 'Gewinnspiel', value: 'lottery' },
    ],
};

const referenceOptions = {
    promotion: [
        { label: 'Verkaufsauftrag', value: 'sellOrder' },
        { label: 'Bestellung', value: 'buyOrder' },
        { label: 'Ankaufartikel', value: 'sellArticle' },
        { label: 'Shopartikel', value: 'buyArticle' },
        { label: 'Ankaufkategorie', value: 'sellCategory' },
        { label: 'Shopsuche (Kategorie / mit Filtern)', value: 'buyCategory' },
        { label: 'Verkaufskorb', value: 'sellBasket' },
        { label: 'Warenkorb', value: 'buyBasket' },
        { label: 'Artikelanfrage', value: 'articleRequest' },
        { label: 'Gewinnspiel', value: 'lottery' },
        { label: 'Liveshow', value: 'liveshow' },
        { label: 'Benutzerdefiniert', value: 'customTab' },
    ],
    selectedPromotion: [
        { label: 'Verkaufsauftrag', value: 'sellOrder' },
        { label: 'Bestellung', value: 'buyOrder' },
        { label: 'Ankaufartikel', value: 'sellArticle' },
        { label: 'Shopartikel', value: 'buyArticle' },
        { label: 'Ankaufkategorie', value: 'sellCategory' },
        { label: 'Shopsuche (Kategorie / mit Filtern)', value: 'buyCategory' },
        { label: 'Verkaufskorb', value: 'sellBasket' },
        { label: 'Warenkorb', value: 'buyBasket' },
        { label: 'Artikelanfrage', value: 'articleRequest' },
        { label: 'Gewinnspiel', value: 'lottery' },
        { label: 'Liveshow', value: 'liveshow' },
        { label: 'Benutzerdefiniert (URL)', value: 'customTab' },
    ],
    sellBasketReminder: [
        { label: 'Verkaufskorb', value: 'sellBasket' },
    ],
    buyBasketReminder: [
        { label: 'Warenkorb', value: 'buyBasket' },
    ],
    sellOrder: [
        { label: 'Verkaufsauftrag', value: 'sellOrder' },
    ],
    buyOrder: [
        { label: 'Bestellung', value: 'buyOrder' },
    ],
    priceAlert: [
        { label: 'Shopartikel', value: 'buyArticle' },
    ],
    backInStock: [
        { label: 'Shopartikel', value: 'buyArticle' },
    ],
    readyForSell: [
        { label: 'Ankaufartikel', value: 'sellArticle' },
    ],
    lottery: [
        { label: 'Gewinnspiel', value: 'lottery' },
    ],
    liveshow: [
        { label: 'Liveshow', value: 'liveshow' },
    ],
};

const AppPushEditor = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const isEditing = location.pathname === '/admin-push/edit';
    const [imgUrl, setImgUrl] = useState(null);

    const { showToast } = useToast();
    const [loading, setLoading] = useState(true);

    const [isSaving, setIsSaving] = useState(false);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [audience, setAudience] = useState(audienceOptions[0].value);
    const [type, setType] = useState('');
    const [reference, setReference] = useState('');
    const [referenceId, setReferenceId] = useState('');
    const [userIds, setUserIds] = useState('');
    const [args, setArgs] = useState('');

    const [titleError, setTitleError] = useState(false);
    const [textError, setTextError] = useState(false);
    const [referendeIdError, setReferenceIdError] = useState(false);
    const [argsError, setArgsError] = useState(false);
    const [userIdsError, setUserIdsError] = useState(false);

    const [time, setTime] = React.useState(() => {
        const today = new Date();
        const start = today;
        const end = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());

        return {
            start: parseAbsoluteToLocal(start.toISOString()),
            end: parseAbsoluteToLocal(end.toISOString()),
        };
    });

    const [uploadedImage, setUploadedImage] = useState(null);

    const handleImageChange = (file) => {
        setUploadedImage(file);
    };

    useEffect(() => {
        const initialize = async () => {
            setLoading(true);
            try {
                if (isEditing && id) {
                    await fetchPush(id);
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        initialize();
    }, [isEditing, id]);

    const fetchPush = async (id) => {
        try {
            const response = await zoxsManagementService.getPushById(id);
            if (response && response.element) {
                const {
                    title,
                    text,
                    start,
                    end,
                    args,
                    imgUrl,
                    audience,
                    type,
                    reference,
                    referenceId,
                    userIds,
                } = response.element;

                setImgUrl(imgUrl);
                setTitle(title);
                setText(text);
                setTime({
                    start: parseAbsoluteToLocal(new Date(start).toISOString()),
                    end: parseAbsoluteToLocal(new Date(end).toISOString()),
                });
                setArgs(args ? JSON.stringify(args) : '');
                setUploadedImage(imgUrl);
                setAudience(audience || audienceOptions[0].value);
                setType(type || '');
                setReference(reference || '');
                setUserIds(userIds ? userIds.join(',') : '');
            } else {
                showToast('Push konnte nicht geladen werden.', 'error');
            }
        } catch (error) {
            showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
        }
    };

    const generateJson = () => {
        const startDate = new Date(
            time.start.year,
            time.start.month - 1,
            time.start.day,
            time.start.hour,
            time.start.minute,
            time.start.second,
            time.start.millisecond
        );

        const endDate = new Date(
            time.end.year,
            time.end.month - 1,
            time.end.day,
            time.end.hour,
            time.end.minute,
            time.end.second,
            time.end.millisecond
        );

        let adjustedArgs = null;
        if (reference === 'customTab' || reference === 'buyCategory') {
            try {
                adjustedArgs = JSON.parse(args);
            } catch (error) {
                adjustedArgs = {};
            }
        }

        return {
            id: id || null,
            title: title || null,
            message: text || null,
            type,
            referenceType: reference,
            referenceId: referenceId ? referenceId : null,
            route: null,
            args: adjustedArgs ? JSON.stringify(adjustedArgs) : null,
            imageUrl: null,
            start: startDate,
            end: endDate,
            audience,
            userIds: audience === 'custom' ? userIds.split(',').map(id => parseInt(id.trim())).filter(id => !isNaN(id)) : null,
        };
    };

    const showDev = () => {
        onOpen();
    }

    const handleSubmit = async () => {
        let valid = true;

        const now = new Date();
        const startDate = new Date(Date.UTC(
            time.start.year,
            time.start.month - 1,
            time.start.day,
            time.start.hour,
            time.start.minute,
            time.start.second,
            time.start.millisecond
        ));

        const endDate = new Date(Date.UTC(
            time.end.year,
            time.end.month - 1,
            time.end.day,
            time.end.hour,
            time.end.minute,
            time.end.second,
            time.end.millisecond
        ));

        if (!title) {
            setTitleError(true);
            showToast('Titel ist erforderlich.', 'error');
            valid = false;
        }

        if (!text) {
            setTextError(true);
            showToast('Beschreibung ist erforderlich.', 'error');
            valid = false;
        }

        if (!time || endDate < now || endDate < startDate) {
            showToast('Bitte wähle ein gültiges Start- und Enddatum.', 'error');
            valid = false;
        }

        if (!audience) {
            showToast('Zielgruppe ist erforderlich.', 'error');
            valid = false;
        }

        if (!type) {
            showToast('Typ ist erforderlich.', 'error');
            valid = false;
        }

        if (!reference) {
            showToast('Nachrichtenreferenz ist erforderlich.', 'error');
            valid = false;
        }

        if ((reference === 'customTab' || reference === 'buyCategory') && !args) {
            setArgsError(true);
            showToast('Arguments sind erforderlich.', 'error');
            valid = false;
        } else if ((reference === 'sellOrder' || reference === 'buyOrder' || reference === 'sellArticle' || reference === 'buyArticle' || reference === 'sellCategory' || reference === 'buyCategory' || reference === 'liveshow' || reference === 'articleRequest') && !referenceId) {
            setReferenceIdError(true);
            showToast('Referenz-ID ist erforderlich.', 'error');
            valid = false;
        }

        if (audience === 'custom') {
            if (!/^[0-9,]*$/.test(userIds)) {
                setUserIdsError(true);
                showToast('Benutzer-IDs müssen nur Zahlen und Kommas enthalten.', 'error');
                valid = false;
            }
        }

        if (!valid) {
            return;
        }

        const jsonBody = generateJson();

        try {
            setIsSaving(true);
            const formData = new FormData();

            if (uploadedImage) {
                formData.append("image", uploadedImage);
            }

            Object.keys(jsonBody).forEach((key) => {
                if (Array.isArray(jsonBody[key])) {
                    jsonBody[key].forEach((item) => formData.append(`${key}[]`, item));
                } else {
                    formData.append(key, jsonBody[key]);
                }
            });

            if (isEditing) {
                await zoxsManagementService.updatePush(jsonBody);
                showToast('Push erfolgreich bearbeitet.', 'success');
                navigate('/admin-push');
            } else {
                await zoxsManagementService.createPush(formData);
                showToast('Push erfolgreich erstellt.', 'success');
                navigate('/admin-push');
            }
        } catch (error) {
            showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
        } finally {
            setIsSaving(false);
        }
    };

    const handleClose = () => {
        navigate('/admin-push');
    };

    return (
        (<div className="w-full flex-1 p-4">
            <Icon
                className="text-default-400 absolute top-4 right-4 cursor-pointer z-50"
                icon="solar:close-square-bold"
                width={28}
                height={28}
                onClick={handleClose}
            />
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">
                    {isEditing ? 'Push bearbeiten' : 'Push erstellen'}
                </h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Erstelle oder bearbeite eine Push-Kampagne.
            </h2>
            {loading ? (
                <Spinner />
            ) : (
                <div className="mt-4 flex flex-col space-y-4">
                    {isEditing ? <div className="w-full flex justify-center">
                        <Tooltip showArrow content="Bilder können nicht mehr geändert werden.">
                            <div
                                className="relative border border-gray-300 rounded-lg overflow-hidden max-w-xl"
                                style={{ aspectRatio: '16 / 9' }}
                            >
                                <img src={imgUrl} alt="Uploaded" className="w-full h-full object-cover" />
                            </div>
                        </Tooltip>
                    </div> : <AppPromoImageUpload onImageChange={handleImageChange} />}
                    <div className="flex justify-between items-center">
                        <Autocomplete
                            label="Zielgruppe"
                            variant="bordered"
                            defaultItems={audienceOptions}
                            placeholder="Wähle die Zielgruppe"
                            selectedKey={audience}
                            onSelectionChange={(value) => setAudience(value)}
                        >
                            {(item) => <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>}
                        </Autocomplete>
                        <Spacer x={2} />
                        <Autocomplete
                            label="Typ"
                            variant="bordered"
                            defaultItems={typeOptions[audience]}
                            placeholder="Wähle den Typ"
                            selectedKey={type}
                            onSelectionChange={(value) => setType(value)}
                        >
                            {(item) => <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>}
                        </Autocomplete>
                    </div>
                    {audience === 'custom' && (
                        <Input
                            variant="bordered"
                            type="text"
                            size="md"
                            label="User IDs (mit Komma trennen)"
                            value={userIds}
                            labelPlacement="inside"
                            isInvalid={userIdsError}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9,]/g, '');
                                setUserIds(value);
                                setUserIdsError(false);
                            }}
                        />
                    )}
                    <div className="flex justify-between items-center">
                        <Autocomplete
                            label="Nachrichtenreferenz"
                            variant="bordered"
                            defaultItems={referenceOptions[type] || []}
                            placeholder="Wähle die Nachrichtenreferenz"
                            selectedKey={reference}
                            onSelectionChange={(value) => setReference(value)}
                        >
                            {(item) => <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>}
                        </Autocomplete>
                        <Spacer x={2} />
                        <Input
                            variant="bordered"
                            type="text"
                            size="md"
                            label="Referenz-ID (z.B. Artikel-ID, Order-ID etc.)"
                            value={referenceId}
                            labelPlacement="inside"
                            isInvalid={referendeIdError}
                            onChange={(e) => {
                                setReferenceId(e.target.value);
                                setReferenceIdError(false);
                            }}
                        />
                    </div>
                    {(reference === 'customTab' || reference === 'buyCategory') && (
                        <div className="flex items-center w-full gap-4">
                            <Input
                                variant="bordered"
                                type="text"
                                size="md"
                                label="Arguments (JSON)"
                                value={args}
                                defaultValue=""
                                labelPlacement="inside"
                                isInvalid={argsError}
                                startContent={
                                    <Icon
                                        className="text-default-400 pointer-events-none"
                                        height={18}
                                        icon="solar:code-bold"
                                        width={18}
                                    />
                                }
                                onChange={(e) => {
                                    setArgs(e.target.value);
                                    setArgsError(false);
                                }}
                            />
                        </div>
                    )}
                    <div className="flex justify-between items-center">
                        <Input
                            variant="bordered"
                            type="text"
                            size='md'
                            label="Titel"
                            value={title}
                            defaultValue=""
                            labelPlacement="inside"
                            isInvalid={titleError}
                            startContent={
                                <Icon
                                    className="text-default-400 pointer-events-none"
                                    height={18}
                                    icon="solar:text-square-outline"
                                    width={18}
                                />
                            }
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setTitleError(false);
                            }}
                        />
                        <Spacer x={2} />
                        <I18nProvider locale="de">
                            <DateRangePicker
                                label="Zeitraum"
                                value={time}
                                variant='bordered'
                                onChange={setTime}
                                visibleMonths={3}
                                startContent={
                                    <Icon
                                        className="text-default-400 pointer-events-none"
                                        height={18}
                                        icon="solar:calendar-date-outline"
                                        width={18}
                                    />
                                }
                            />
                        </I18nProvider>
                    </div>
                    <Textarea
                        variant="bordered"
                        type="text"
                        size='md'
                        label="Nachricht"
                        value={text}
                        defaultValue=""
                        labelPlacement="inside"
                        isInvalid={textError}
                        startContent={
                            <Icon
                                className="text-default-400 pointer-events-none"
                                height={18}
                                icon="solar:document-text-outline"
                                width={18}
                            />
                        }
                        onChange={(e) => {
                            setText(e.target.value);
                            setTextError(false);
                        }}
                    />
                    <div className="flex justify-end">
                        <Button
                            onPress={showDev}
                            color="primary"
                            size="lg"
                            variant='ghost'
                            className="py-1 px-3"
                        >
                            JSON Output
                        </Button>
                        <Spacer x={2} />
                        <Button
                            onPress={handleSubmit}
                            isLoading={isSaving}
                            color="primary"
                            size="lg"
                            className="py-1 px-3"
                        >
                            {isEditing ? 'Push bearbeiten' : 'Push erstellen'}
                        </Button>
                    </div>
                </div>)
            }
            <Modal
                size='full'
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">JSON-Output</ModalHeader>
                            <ModalBody>
                                <JsonView src={generateJson()} />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={onClose}>
                                    Schließen
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div >)
    );
};

export default AppPushEditor;
