import React, { useEffect, useState } from "react";
import { Card, Spinner, Listbox, ListboxItem, Modal, ModalHeader, ModalBody, Button, ModalContent, ModalFooter, useDisclosure, Chip, Link } from "@heroui/react";
import wawiService from "@/services/wawiService";
import { Icon } from "@iconify/react/dist/iconify.js";

function WaWiDixalistComponent({ query }) {
    const [isLoading, setIsLoading] = useState(false);
    const [dixaConversations, setDixaConversations] = useState(null);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [emailHistory, setEmailHistory] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const { conversations: fetchedConversations } = await wawiService.getDixaConversations(query);

                if (fetchedConversations) {
                    setDixaConversations(fetchedConversations);
                } else {
                    throw new Error("Keine Dixa-Conversations gefunden.");
                }
            } catch (err) {
                setError(err.message || "Ein unbekannter Fehler ist aufgetreten.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [query]);

    const handleConversationClick = async (conversationId) => {
        onOpen();
        setSelectedConversation(null);
        setEmailHistory(null);
        try {
            const { conversation: fetchedConversation } = await wawiService.getDixaConversationById(conversationId);

            if (fetchedConversation) {
                setSelectedConversation(fetchedConversation);
                setEmailHistory(fetchedConversation);
            } else {
                throw new Error("Keine Dixa-Conversation gefunden.");
            }
        } catch (err) {
            setError(err.message || "Ein unbekannter Fehler ist aufgetreten.");
        }
    };

    return (
        (<div className="wawi-dixa-conversations-component">
            {isLoading ? (
                <div className="flex justify-center">
                    <Spinner size="lg" aria-label="Lade Dixa-Conversations..." />
                </div>
            ) : error ? (
                <Card className="w-full rounded-lg border bg-background p-4 shadow">
                    <h3 className="text-red-600 font-bold text-xl">Fehler</h3>
                    <p className="text-red-500">{error}</p>
                </Card>
            ) : dixaConversations ? (
                <Listbox shouldHighlightOnFocus variant="flat" aria-label="Dixa Conversations List" classNames={
                    { wrapper: "p-0", }
                }>
                    {dixaConversations.map((conversation) => (
                        <ListboxItem
                            key={conversation.id}
                            description={`ID: ${conversation.id}`}
                            onPress={() => handleConversationClick(conversation.id)}
                            endContent={
                                <Icon
                                    className="text-default-500"
                                    icon="solar:alt-arrow-right-outline"
                                    width={24}
                                />
                            }
                        >
                            {conversation.highlights?.subject?.[0]?.replace(/<\/?em[^>]*>/g, "") || "Kein Betreff"}
                        </ListboxItem>
                    ))}
                </Listbox>
            ) : (
                <p className="text-default-500">Keine Dixa-Conversations verfügbar.</p>
            )}
            <Modal size="5xl"
                isOpen={isOpen}
                backdrop="blur"
                scrollBehavior="inside"
                onClose={onClose}>
                <ModalContent>
                    {(onClose) => (<>
                        <ModalHeader className="flex flex-col gap-1">E-Mail-Verlauf</ModalHeader>
                        <ModalBody>
                            {selectedConversation && emailHistory ? (
                                emailHistory.map((email) => (
                                    <div key={email.id} className="email-history-item mb-2">
                                        <Card className="w-full rounded-lg border bg-background p-4 shadow relative">
                                            {email.attributes.isAutoReply ? (
                                                <div className="absolute top-4 right-4">
                                                    <Chip color="warning">Auto-Reply</Chip>
                                                </div>
                                            ) : email.attributes.direction === "Outbound" ? (
                                                <div className="absolute top-4 right-4">
                                                    <Chip color="primary">Ausgehend</Chip>
                                                </div>
                                            ) : email.attributes.direction === "Inbound" ? (
                                                <div className="absolute top-4 right-4">
                                                    <Chip color="secondary">Eingehend</Chip>
                                                </div>
                                            ) : null}
                                            <p><strong>Von:</strong> {email.attributes.from.email}</p>
                                            <p><strong>An:</strong> {email.attributes.to.map((recipient) => recipient.email).join(", ")}</p>
                                            <p><strong>Datum:</strong> {new Date(email.createdAt).toLocaleString()}</p>
                                            <div className="mt-2">
                                                <strong>Nachricht:</strong>
                                                <div
                                                    className="email-content mt-1"
                                                    dangerouslySetInnerHTML={{ __html: email.attributes.emailContent.content.value }}
                                                />
                                            </div>
                                            {email.attributes.inlineImages.length > 0 && (
                                                <div className="mt-2">
                                                    <strong>Bilder:</strong>
                                                    <ul>
                                                        {email.attributes.inlineImages.map((image, index) => (
                                                            <Link isBlock showAnchorIcon href={`https://raw.dixausercontent.com${image.url}`} isExternal color="foreground">
                                                                {image.prettyName}
                                                            </Link>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {email.attributes.attachments.length > 0 && (
                                                <div className="mt-2">
                                                    <strong>Anhänge:</strong>
                                                    <ul>
                                                        {email.attributes.attachments.map((attachment, index) => (
                                                            <Link isBlock showAnchorIcon href={`https://raw.dixausercontent.com${attachment.url}`} isExternal color="foreground">
                                                                {attachment.prettyName}
                                                            </Link>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </Card>
                                    </div>
                                ))
                            ) : (
                                <div className="flex justify-center">
                                    <Spinner size="lg" aria-label="Lade E-Mail-Verlauf..." />
                                </div>
                            )}
                        </ModalBody>
                    </>)}
                </ModalContent>
            </Modal>
        </div>)
    );
}

export default WaWiDixalistComponent;
