import React, { useEffect, useState } from "react";
import { Tab, Tabs, Spinner, Button, Select, SelectItem, Card, CardHeader, Avatar, CardBody, Tooltip } from "@heroui/react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import mailService from "@/services/mailService";
import TimeAgo from 'react-timeago';
import germanStrings from 'react-timeago/lib/language-strings/de';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { Icon } from "@iconify/react/dist/iconify.js";

const formatter = buildFormatter(germanStrings);

const MailOverview = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [mails, setMails] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const { mails: mails } = await mailService.getMails();

                setMails(mails);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCreateGrapesMail = () => {
        navigate('/admin-mail/grapes/create');
    };
    const handleCreateEeMail = () => {
        navigate('/admin-mail/easyemail/create');
    };

    const handleDuplicate = (item) => {
        if (item.editor === "GRAPE") {
            navigate(`/admin-mail/grapes/create?duplicate=${item.id}`);
        } else if (item.editor === "EASYEMAIL") {
            navigate(`/admin-mail/easyemail/create?duplicate=${item.id}`);
        } else {
            console.warn("Unknown editor type:", item.editor);
        }
    };

    const handleEdit = (item) => {
        if (item.editor === "GRAPE") {
            navigate(`/admin-mail/grapes/edit?id=${item.id}`);
        } else if (item.editor === "EASYEMAIL") {
            navigate(`/admin-mail/easyemail/edit?id=${item.id}`);
        } else {
            console.warn("Unknown editor type:", item.editor);
        }
    };

    return (
        <div className="w-full h-full flex-1 p-4" style={{ overflowY: "auto" }}>
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">ZOXS Mailer: Designer</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500">
                Gestalte Newsletter oder andere Mails
            </h2>

            <div className="flex items-center gap-x-3 mt-4">
                <div className="flex-grow" />
                {/* <Button size="lg" color="primary" onPress={handleCreateEeMail}>
                    EasyEmail
                </Button> */}
                <Button size="lg" color="primary" onPress={handleCreateGrapesMail}>
                    E-Mail erstellen
                </Button>
            </div>

            {isLoading ? (
                <Spinner size="large" />
            ) : (<div className="mt-4 space-y-4">
                {mails.length > 0 ? (
                    mails.map((item) => (
                        <div key={item.id} className="space-y-4">
                            <Card className="w-full rounded-lg border bg-background shadow p-2">
                                <CardHeader className="justify-between items-center flex">
                                    <div className="flex gap-5">
                                        <Avatar
                                            isBordered
                                            radius="full"
                                            size="md"
                                            src={item?.user?.avatarUrl}
                                        />
                                        <div className="flex flex-col gap-1 items-start justify-center">
                                            <h4 className="text-small font-semibold leading-none text-default-600">
                                                {item?.user?.name}
                                            </h4>
                                            <h5 className="text-small tracking-tight text-default-400">
                                                <TimeAgo
                                                    date={new Date(
                                                        item.updated._seconds * 1000 + item.updated._nanoseconds / 1000000
                                                    )}
                                                    formatter={formatter}
                                                />
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="flex gap-3">
                                        <Button
                                            color="primary"
                                            radius="full"
                                            size="sm"
                                            variant="ghost"
                                            onPress={() => handleEdit(item)}
                                        >
                                            Bearbeiten
                                        </Button>
                                        <Tooltip content="Duplizieren">
                                            <Button
                                                isIconOnly
                                                color="primary"
                                                variant="light"
                                                aria-label="Duplizieren"
                                                size="sm"
                                                onPress={() => handleDuplicate(item)}
                                            >
                                                <Icon className="text-default-400" icon="solar:copy-outline" width={20} />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </CardHeader>

                                <CardBody className="px-3 py-0 text-small text-default-400">
                                    <p>{item.title}</p>
                                    <span className="pt-2">
                                        {item.editor}
                                    </span>
                                </CardBody>
                            </Card>
                        </div>
                    ))
                ) : (
                    <p>Noch keine Mails vorhanden</p>
                )}
            </div>
            )}
        </div>
    );
}

export default MailOverview;