import React, { useState, useEffect } from 'react';
import { Image, Spinner } from "@heroui/react";

const GiphyGif = ({ gifId }) => {
    const [gifUrl, setGifUrl] = useState('');

    useEffect(() => {
        const apiKey = 'eeyzwDUmYxp06X9tncKr6tl0XzLYdray';
        const url = `https://api.giphy.com/v1/gifs/${gifId}?api_key=${apiKey}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                const gifUrl = data.data.images.original.url;
                setGifUrl(gifUrl);
            })
            .catch(error => console.error('Error fetching Giphy GIF:', error));
    }, [gifId]); // Effect dependency array

    if (gifUrl) {
        return (
            <div className='w-full flex justify-center'>
                <Image
                src={gifUrl}
                alt="Giphy GIF"
                width={300}
            />
            </div>
        );
    } else {
        return <Spinner />;
    }
};

export default GiphyGif;
