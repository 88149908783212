"use client";

import React from "react";
import { Button, Spacer } from "@heroui/react";
import { cn } from "@heroui/react";
import ComingSoon from "@/components/ComingSoon";

const PushSettings = React.forwardRef(({ className, ...props }, ref) => {

  return (
    <ComingSoon></ComingSoon>
  );

  return (
    <div ref={ref} className={cn("p-2", className)} {...props}>
      <div>
        <p className="text-base font-medium text-default-700">Feed & Posts</p>
        <p className="mt-1 text-sm font-normal text-default-400">Wie Neugierig bist Du wirklich?</p>
      </div>
      <Spacer y={4} />
      <div>
        <p className="text-base font-medium text-default-700">Chats</p>
        <p className="mt-1 text-sm font-normal text-default-400">Möchtest Du bei neuen Nachrichten eine Info erhalten?</p>
      </div>
      <Spacer y={4} />
      <div>
        <p className="text-base font-medium text-default-700">Mittagstisch</p>
        <p className="mt-1 text-sm font-normal text-default-400">Für welche Standorte und Events möchtest Du benachrichtigt werden?</p>
      </div>
      <Button className="mt-4 bg-primary text-background" size="md">
        Speichern
      </Button>
    </div>
  );
});

PushSettings.displayName = "PushSettings";

export default PushSettings;
