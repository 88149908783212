import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Avatar, Button, Modal, ModalBody, ModalContent, ModalHeader, ScrollShadow, Spinner, Tooltip, useDisclosure } from "@heroui/react";
import { Icon } from '@iconify/react/dist/iconify.js';
import feedService from '@/services/feedService';
import userService from '@/services/userService';
import AppDownloadContent from './AppDownloadContent';

function StoryList() {
    const [stories, setStories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const storiesContainerRef = useRef(null);
    const { isOpen: isModOpen, onOpen: onModOpen, onOpenChange: onModOpenChange } = useDisclosure();

    const fetchStories = useCallback(async () => {
        try {
            const allStories = await feedService.getStories();
            const reorderedStories = reorderStories(allStories.stories);
            setStories(reorderedStories);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, []);

    const reorderStories = (stories) => {
        const userId = userService.user.id;
        const notFullyViewed = [];
        const fullyViewed = [];

        stories.forEach((story) => {
            const allViewed = story.storyItems.every((item) => item.seenBy.includes(userId));
            if (allViewed) {
                fullyViewed.push(story);
            } else {
                notFullyViewed.push(story);
            }
        });
        return [...notFullyViewed, ...fullyViewed];
    };

    useEffect(() => {
        fetchStories();
    }, [fetchStories]);

    const handleScroll = () => {
        const { scrollLeft, offsetWidth, scrollWidth } = storiesContainerRef.current;
        const maxScrollLeft = scrollWidth - offsetWidth;
        const newStoryPage = Math.round((scrollLeft / maxScrollLeft) * (stories.length / 8));
    };

    const scrollLeft = () => {
        if (storiesContainerRef.current) {
            storiesContainerRef.current.scrollBy({
                left: -200,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (storiesContainerRef.current) {
            storiesContainerRef.current.scrollBy({
                left: 200,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="relative">
            {isLoading ? (
                <div className="flex justify-center">
                    <Spinner />
                </div>
            ) : stories.length > 0 ? (
                <>
                    <Button
                        isIconOnly
                        color="primary"
                        size="sm"
                        aria-label="Scroll Left"
                        className="absolute top-1/4 -left-4 rounded-full p-2 z-10"
                        onPress={scrollLeft}
                    >
                        <Icon icon="solar:alt-arrow-left-linear" width={24} />
                    </Button>

                    <ScrollShadow
                        className="flex gap-1 items-center overflow-x-auto"
                        ref={storiesContainerRef}
                        hideScrollBar
                        orientation="horizontal"
                        offset={100}
                    >
                        {stories.map((story, index) => (
                            <div
                                key={index}
                                style={{
                                    width: '55px',
                                    height: '55px',
                                    aspectRatio: '1',
                                    overflow: 'visible',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip showArrow={true} content={story.user.firstname}>
                                    <Avatar
                                        onClick={onModOpen}
                                        isBordered
                                        color={story.storyItems.some(
                                            (item) => !item.seenBy.includes(userService.user.id)
                                        ) ? 'primary' : 'default'}
                                        src={story.user.avatarUrl}
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                </Tooltip>
                            </div>
                        ))}
                    </ScrollShadow>

                    <Button
                        isIconOnly
                        color="primary"
                        size="sm"
                        aria-label="Scroll Right"
                        className="absolute top-1/4 -right-4 rounded-full p-2 z-10"
                        onPress={scrollRight}
                    >
                        <Icon icon="solar:alt-arrow-right-linear" width={24} />
                    </Button>
                </>
            ) : (
                <div className="flex flex-col items-center justify-center h-full text-center p-4 cursor-pointer" onClick={onModOpen}>
                    <Icon icon="solar:history-3-outline" width={25} />
                    <p className="mt-4 text-small text-default-500">
                        Aktuell gibt es keine Stories. Lade Dir hier die inteam-App herunter und
                        erstelle die erste Story!
                    </p>
                </div>
            )}
            <Modal
                isOpen={isModOpen}
                onOpenChange={onModOpenChange}
                backdrop='blur'
            >
                <ModalContent>
                    {(onModClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">App-Download</ModalHeader>
                            <ModalBody>
                                <AppDownloadContent />
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}

export default StoryList;