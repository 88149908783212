import React, { useState, useEffect } from 'react';
import { Avatar, AvatarGroup, Popover, PopoverTrigger, PopoverContent } from "@heroui/react";
import ReactionComponent from './Reaction';

const ReactionList = ({ reactions, whiteBackground = false }) => {
    const [firstReactions, setFirstReactions] = useState([]);

    useEffect(() => {
        const getFirstReactions = () => {
            let emojiCounts = {};

            reactions.forEach((reaction) => {
                const emoji = reaction.emoji;
                emojiCounts[emoji] = (emojiCounts[emoji] ?? 0) + 1;
            });

            const sortedEmojis = Object.entries(emojiCounts).sort((a, b) => b[1] - a[1]);

            const topThreeEmojis = sortedEmojis.slice(0, 3).map(entry => entry[0]);

            setFirstReactions(topThreeEmojis);
        };

        getFirstReactions();
    }, [reactions]);

    const content = (
        <PopoverContent className="w-[300px] overflow-hidden">
            {(titleProps) => (
                <div className="px-1 py-2 w-full max-h-[400px] overflow-y-auto">
                    <p className="text-small font-bold text-foreground" {...titleProps}>
                        Reaktionen
                    </p>
                    <div className="mt-2 flex flex-col gap-1 w-full">
                        {reactions.map(reaction => (
                            <ReactionComponent reaction={reaction} key={reaction.id} />
                        ))}
                    </div>
                </div>
            )}
        </PopoverContent>
    );

    return (
        <Popover
            showArrow
            offset={10}
            placement="bottom"
            backdrop="blur"
        >
            <PopoverTrigger>
                <AvatarGroup
                    max={3}
                    total={reactions.length}
                    renderCount={() => (
                        <p className="text-default-400 text-small ml-2">{reactions.length === 1 ? '1 Reaktion' : `${reactions.length} Reaktionen`}</p>
                    )}
                >
                    {firstReactions.map((emoji, index) => (
                        <Avatar
                            key={index}
                            classNames={{
                                base: whiteBackground ? "bg-white" : "bg-default-100",
                            }}
                            size="sm"
                            showFallback
                            fallback={
                                <p className='text-lg'>{emoji}</p>
                            }
                            src=""
                        />
                    ))}
                </AvatarGroup>
            </PopoverTrigger>
            {content}
        </Popover>
    );
};

export default ReactionList;