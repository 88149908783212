import { Button, Image } from "@heroui/react";
import { useNavigate } from "react-router-dom";
import npZoxsy from '../assets/svg/zoxsy_no_rights.svg'

function NoPermission() {
    const navigate = useNavigate();

    const requestPermission = () => {
        alert('Diese Funktion folgt noch...');
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="p-6 bg-background shadow-lg rounded-lg" css={{ maxWidth: '500px', textAlign: 'center' }}>
                <div className="flex justify-center mb-4">
                    <Image
                        width={215}
                        alt="Keine Modulrechte"
                        src={npZoxsy}
                    />
                </div>
                <h2 className="text-xl font-bold mb-2 text-center">Ups - Du hast Dich wohl verlaufen.</h2>
                <p className="text-default-500 mb-6 text-center">Für dieses Modul hast Du keine Berechtigung.</p>
                <div className="flex flex-col gap-3">
                    <Button color="secondary" onPress={requestPermission} auto>
                        Recht anfragen
                    </Button>
                    <Button color="primary" onPress={() => navigate('/')} auto>
                        Zurück zur Startseite
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default NoPermission;
