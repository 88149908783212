import React from "react";
import { Icon } from "@iconify/react";
import { Alert } from "@heroui/react";

const getSellOrderStatusData = (status) => {
    const statusMap = {
        cancelled: {
            label: "Storniert",
            icon: <Icon className="text-default-500" icon="solar:close-circle-outline" width={24} />,
        },
        received: {
            label: "Paketeingang",
            icon: <Icon className="text-default-500" icon="solar:box-outline" width={24} />,
        },
        completed: {
            label: "Abgeschlossen",
            icon: <Icon className="text-default-500" icon="solar:check-circle-outline" width={24} />,
        },
        conflicted: {
            label: "Neues Angebot liegt vor",
            icon: <Icon className="text-default-500" icon="solar:chat-round-money-outline" width={24} />,
        },
        returnProcessing: {
            label: "Vorbereitung auf Rückversand",
            icon: <Icon className="text-default-500" icon="solar:undo-left-round-outline" width={24} />,
        },
        returned: {
            label: "Rückversand verschickt",
            icon: <Icon className="text-default-500" icon="solar:undo-left-round-outline" width={24} />,
        },
        inReview: {
            label: "In Bearbeitung",
            icon: <Icon className="text-default-500" icon="solar:magnifer-outline" width={24} />,
        },
        unknown: {
            label: "Unbekannter Status",
            icon: <Icon className="text-default-500" icon="solar:question-circle-outline" width={24} />,
        },
        created: {
            label: "Schon verschickt?",
            icon: <Icon className="text-default-500" icon="solar:cart-plus-outline" width={24} />,
        },
        conflictedPositive: {
            label: "Neues Angebot liegt vor - du erhältst mehr",
            icon: <Icon className="text-default-500" icon="solar:check-circle-outline" width={24} />,
        },
        customerReview: {
            label: "Du bist gefragt",
            icon: <Icon className="text-default-500" icon="solar:star-fall-minimalistic-2-outline" width={24} />,
        },
        priceRequest: {
            label: "Preisanfrage",
            icon: <Icon className="text-default-500" icon="solar:calculator-linear" width={24} />,
        },
        returnPaymentPending: {
            label: "Warten auf Rückversand-Zahlung",
            icon: <Icon className="text-default-500" icon="solar:hourglass-line-outline" width={24} />,
        },
        completedPending: {
            label: "Die Auszahlung wird demnächst erfolgen",
            icon: <Icon className="text-default-500" icon="solar:check-circle-outline" width={24} />,
        },
        partReturn: {
            label: "Teilrückversand in Vorbereitung",
            icon: <Icon className="text-default-500" icon="solar:undo-left-round-outline" width={24} />,
        },
        payoutError: {
            label: "Falsche Auszahlungsdaten",
            icon: <Icon className="text-default-500" icon="solar:bill-cross-outline" width={24} />,
        },
        rejected: {
            label: "Ankauf abgelehnt",
            icon: <Icon className="text-default-500" icon="solar:shield-cross-outline" width={24} />,
        },
    };

    return statusMap[status] || {
        label: "Unbekannter Status",
        icon: <Icon className="text-default-500" icon="solar:question-circle-outline" width={24} />,
    };
};

const WaWiSellOrderStatusAlert = ({ order }) => {
    const { label, icon } = getSellOrderStatusData(order.status);
    const difference = order.sumOrigin && order.sumNew ? (order.sumOrigin - order.sumNew).toFixed(2) : null;
    const isZoxsCheck = order.zoxsCheck && order.premiumFee === 0.01;

    return (
        <>
            <div className="space-y-2">
                <Alert icon={icon}>{label}</Alert>

                {order.sumNew !== null && order.sumNew === order.sumOrigin && (
                    <Alert color="success" variant="faded" hideIconWrapper>
                        Haken. ({order.sumOrigin.toFixed(2)}€)
                    </Alert>
                )}

                {order.sumNew !== null && order.sumNew < order.sumOrigin && difference && (
                    <Alert color="danger" variant="faded" hideIconWrapper>
                        Unstimmigkeit. Differenz: {difference}€
                    </Alert>
                )}

                {order.status === "received" && order.estimatedProcessingDate && (
                    <Alert variant="faded" hideIconWrapper>
                        Geschätztes Bearbeitungsdatum: {order.estimatedProcessingDate}
                    </Alert>
                )}

                {isZoxsCheck && (
                    <Alert variant="faded" hideIconWrapper>
                        ZOXS Check erfolgreich. Premium-Gebühr: {order.premiumFee.toFixed(2)}€
                    </Alert>
                )}

                {order.premiumFee > 0.01 && (
                    <Alert variant="faded" hideIconWrapper>
                        Premium-Gebühr: {order.premiumFee.toFixed(2)}€
                    </Alert>
                )}
            </div>
        </>
    );
};

export default WaWiSellOrderStatusAlert;
