import { VideoFile } from "../files/videoFile";
import { Reaction } from "./reaction";

class StoryItem {
    constructor({
        id,
        reactions = [],
        videoMedia,
        created,
        imageUrl,
        authorId,
        seenBy = [],
    }) {
        try {
            this.id = id;
            this.reactions = this.validateReactions(reactions);
            this.videoMedia = videoMedia ? new VideoFile(videoMedia) : null;
            this.created = this.parseDate(created);
            this.imageUrl = imageUrl;
            this.authorId = authorId;
            this.seenBy = seenBy;
        } catch (error) {
        }
    }

    validateReactions(reactions) {
        try {
            if (!Array.isArray(reactions)) {
                throw new Error("Reactions must be an array.");
            }
            return reactions.map((reaction) => new Reaction(reaction));
        } catch (error) {
            return [];
        }
    }

    parseDate(date) {
        try {
            if (!date) {
                return null;
            }
            if (typeof date.milliseconds === "number") {
                return new Date(date.milliseconds);
            }
            const parsedDate = new Date(date);
            if (!isNaN(parsedDate)) {
                return parsedDate;
            }

            throw new Error(`Invalid date format: ${JSON.stringify(date)}`);
        } catch (error) {
            return null;
        }
    }

    static fromJson(json) {
        try {
            return new StoryItem({
                ...json,
                videoMedia: json.videoMedia ? new VideoFile(json.videoMedia) : null,
                created: typeof json.created === "number" ? json.created : null,
                reactions: json.reactions
                    ? json.reactions.map((reaction) => Reaction.fromJson(reaction))
                    : [],
            });
        } catch (error) {
            return null;
        }
    }
}

export default StoryItem;
