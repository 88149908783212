import React, { useState, useEffect } from 'react';
import { Card, Avatar, Spinner, CardBody } from "@heroui/react";
import userService from '../services/userService';
import TimeAgo from 'react-timeago';
import germanStrings from 'react-timeago/lib/language-strings/de';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(germanStrings);

const ReactionComponent = ({ reaction }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const fetchedUser = await userService.getUserById(reaction.author);
                setUser(fetchedUser);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [reaction.author]);

    return (
        <Card className="w-full" shadow='none'>
            <CardBody className="">
                <div className="flex justify-between">
                    <div className="flex gap-4">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <>
                                <Avatar
                                    radius="full"
                                    size="sm"
                                    src={user?.avatarUrl}
                                    alt={user?.firstname}
                                />
                                <div className="flex flex-col gap-1 items-start justify-center">
                                    <h4 className="text-small font-semibold leading-none text-default-600">
                                        {user?.firstname} {user?.surname}
                                    </h4>
                                    <h5 className="text-small tracking-tight text-default-400">
                                    <TimeAgo date={reaction.created} formatter={formatter} />
                                    </h5>
                                </div>
                            </>
                        )}
                    </div>
                    <p className='text-lg self-start'>{reaction.emoji}</p>
                </div>
            </CardBody>
        </Card>
    );
};

export default ReactionComponent;
