import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/newsapp.css';
import App from './App';
import { HeroUIProvider } from "@heroui/system";
import ErrorBoundary from './ErrorBoundary';

window.addEventListener("error", (e) => {
    if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
        e.stopImmediatePropagation();
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HeroUIProvider locale="de-DE">
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </HeroUIProvider>
);
