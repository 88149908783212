"use client";

import React, { useEffect, useState } from 'react';
import { I18nProvider } from '@react-aria/i18n';
import 'react18-json-view/src/style.css';
import {
  getLocalTimeZone,
  today
} from "@internationalized/date";
import { Icon } from '@iconify/react/dist/iconify.js';
import { DatePicker, Textarea, Spacer, Button, Spinner, Card, Image, CardBody, CardHeader, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@heroui/react";
import userService from "@/services/userService";
import useToast from '@/hooks/useToast';
import sickZoxsy from '../../assets/svg/zoxsy_sick.svg';

const SickReport = () => {
  const [date, setDate] = useState(today(getLocalTimeZone()));
  const [text, setText] = useState();
  const { showToast } = useToast();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [recovering, setRecovering] = useState(false);
  const [sickHistory, setSickHistory] = useState([]);
  const [error, setError] = useState(false);

  const { isOpen: isSickOpen, onOpen: onSickOpen, onClose: onSickClose } = useDisclosure();
  const { isOpen: isBackOpen, onOpen: onBackOpen, onClose: onBackClose } = useDisclosure();

  const fetchSickHistory = async () => {
    try {
      const history = await userService.getSickHistory();
      if (history.sickHistory) {
        setSickHistory(history.sickHistory);
      } else {
        setError(true);
        showToast('Fehler beim Laden der Krankmeldungen.', 'error');
      }
    } catch (error) {
      setError(true);
      showToast('Fehler beim Laden der Krankmeldungen.', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setDate(today(getLocalTimeZone()));
    } catch (error) {
      setDate(today(getLocalTimeZone()));
    }
  }, []);

  useEffect(() => {
    fetchSickHistory();
  }, []);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleSubmit = async () => {
    setUploading(true);
    const report = { dateStart: `${date.year}-${date.month}-${date.day}`, message: text };
    const { success, message } = await userService.postSickReport(report);

    if (success === true) {
      onSickOpen();
    } else {
      if (message === 'false') {
        showToast('Das hat leider nicht geklappt. Bitte melde Dich kurz vor Arbeitsbeginn bei Deinem Head-Of.', 'error');
      } else {
        showToast(message, 'error');
      }
    }

    setText('');
    setDate(today(getLocalTimeZone()));
    setUploading(false);
    fetchSickHistory();
  };

  const handleBack = async () => {
    setRecovering(true);
    const today = new Date();
    const back = {
      dateStart: `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`,
      message: null
    };
    const { success, message } = await userService.cancelSickReport(back);

    if (success === true) {
      onBackOpen();
    } else {
      if (message === 'false') {
        showToast('Das hat leider nicht geklappt. Bitte melde Dich bei Deinem Head-Of.', 'error');
      } else {
        showToast(message, 'error');
      }
    }
    setRecovering(false);
    fetchSickHistory();
  };

  return (
    <div className='w-full h-full flex-1 p-4'>
      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">Krankmeldung</h1>
      </div>
      <h2 className="mt-2 text-small text-default-500 mb-6">
        Dir geht es nicht gut? Lass es uns wissen.
      </h2>

      <div className="flex flex-col lg:flex-row gap-6">
        <div className="flex-1">
          <I18nProvider locale="de">
            <DatePicker
              variant="bordered"
              label="Startzeit"
              granularity="day"
              minValue={today(getLocalTimeZone())}
              maxValue={today(getLocalTimeZone()).add({ days: 31 })}
              value={date}
              visibleMonths={2}
              onChange={handleDateChange}
            />
          </I18nProvider>
          <Spacer y={2} />
          <Textarea
            variant="bordered"
            type="text"
            size='md'
            label="Anmerkung (falls Du uns etwas mitteilen möchtest)"
            value={text}
            defaultValue=""
            labelPlacement="inside"
            startContent={
              <Icon
                className="text-default-400 pointer-events-none"
                height={18}
                icon="solar:document-text-outline"
                width={18}
              />
            }
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
          <Spacer y={4} />
          <div className="flex justify-end">
            <Button
              onPress={handleSubmit}
              isLoading={uploading}
              color="primary"
              size="lg"
              className="py-1 px-3"
            >
              {'Krankmeldung abschicken'}
            </Button>
          </div>
        </div>
        <div className="flex-1">
          <Card className="rounded-lg border bg-background shadow p-4 min-h-full flex justify-center items-center" fullWidth>
            {loading ? (
              <Spinner size="lg" />
            ) : error || sickHistory.items.length === 0 ? (
              <div className="text-center p-6">
                <div className="flex justify-center mb-4">
                  <Image src={sickZoxsy} alt="Keine Krankheitstage" width={215} />
                </div>
                <h2 className="text-xl font-bold mb-2 text-center">Keine Krankheitstage</h2>
                <p className="text-default-500 text-center">Du hast noch keine Fehltage in diesem Jahr</p>
              </div>
            ) : (
              <div className='w-full'>
                <h3 className="text-lg font-semibold mb-4">Krankheitstage in diesem Jahr: {sickHistory.sickDays}</h3>
                <ul className="space-y-2">
                  {sickHistory.items.map((item, index) => (
                    <Card fullWidth shadow="none" className="p-2 bg-default-100" key={item.id}>
                      <CardHeader>
                        <div className="flex gap-5 justify-between">
                          <div className="flex flex-col gap-1 items-start justify-center">
                            <h4 className="text-small font-semibold leading-none text-default-600">
                              Krankmeldung {new Date().getFullYear()}-{sickHistory.items.length - index}
                            </h4>
                            <h5 className="text-small tracking-tight text-default-400">ID: {item.id}</h5>
                          </div>
                          {item.canEdit && (
                            <Button color="success" radius="full" size="sm" variant="solid" className="text-white" onPress={handleBack} isLoading={recovering}>
                              Wieder da
                            </Button>
                          )}
                        </div>
                      </CardHeader>
                      <CardBody>
                        <span className="text-small font-semibold leading-none text-default-600">
                          Zeitraum: {new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(item.start))}
                          {item.end
                            ? ` - ${new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(item.end))}`
                            : ' (noch offen)'}
                        </span>
                      </CardBody>
                    </Card>
                  ))}
                </ul>
              </div>
            )}
          </Card>
        </div>
      </div>

      <Modal isOpen={isSickOpen} onOpenChange={onSickOpen} backdrop='blur'>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Krankmeldung erfolgreich abgeschickt!</ModalHeader>
              <ModalBody>
                <p>
                  Hey {userService.user.firstname},
                </p>
                <p>
                  wir hoffen, dass es Dir oder Deinem Kind bald wieder besser geht.
                  Eine Bitte: Wenn Du wieder einsatzbereit bist, klicke bitte auf "Wieder da".
                </p>
                <p>
                  Dein:e Head of wird dann automatisch informiert, dass Du am nächsten Werktag zurück bist.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={onSickClose}>
                  Alles klar!
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal isOpen={isBackOpen} onOpenChange={onBackOpen} backdrop='blur'>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Wieder angemeldet!</ModalHeader>
              <ModalBody>
                <p>
                  Hey {userService.user.firstname},
                </p>
                <p>
                  Dein:e Head of wurde informiert und freut sich, dass Du wieder durchstarten kannst.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={onBackClose}>
                  Super!
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SickReport;