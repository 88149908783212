import { Button, Card, CardFooter } from "@heroui/react";
import { Link, useNavigate } from "react-router-dom";

const AppFeedItem = ({ item, isFeedModel }) => {
  const navigate = useNavigate();

  const editPath = isFeedModel
    ? `/admin-feed/edit?id=${item.id}`
    : `/admin-promotion/edit?id=${item.id}`;

  return (
    <Card className="w-full rounded-lg border bg-background shadow">
      <div className="feed-item p-4">
        <div className="flex items-center gap-4">
          {item.imgUrl && (
            <img
              src={item.imgUrl}
              alt={item.title}
              className="h-16 object-cover rounded"
            />
          )}
          <div>
            <h3 className="text-xl font-semibold">{(item.title && item.title !== 'null') ? item.title : 'Kein Titel'}</h3>
            <p className="text-gray-600">{(item.text && item.text !== 'null') ? item.text : 'Keine Beschreibung'}</p>
          </div>
        </div>
        <div className="mt-2 text-gray-500">
          <span>Aktiv ab: {item.start ? item.start.toLocaleString('de-DE') : 'N/A'}</span> |{" "}
          <span>Aktiv bis: {item.end ? item.end.toLocaleString('de-DE') : 'N/A'}</span>
          {/* <span>Erstellt am: {new Date(item.created).toLocaleDateString()}</span> |{" "}
          <span>Erstellt von: {item.createdBy}</span> |{" "}
          <span>Aktualisiert am: {new Date(item.updated).toLocaleDateString()}</span> |{" "}
          <span>Aktualisiert von: {item.updatedBy}</span> */}
        </div>
      </div>
      <CardFooter className="flex justify-between items-center">
        <Button color="primary" variant="bordered" onPress={() => navigate(editPath)}>
          Bearbeiten
        </Button>
      </CardFooter>
    </Card>
  );
};

export default AppFeedItem;  