import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

const WikiContent = ({ item }) => {
    let htmlContent = '';
    if (item.textContent) {
        try {
            const deltaOps = JSON.parse(item.textContent);
            const converter = new QuillDeltaToHtmlConverter(deltaOps, {
                inlineStyles: {
                    color: (value) => {
                        if (!value) {
                            return 'color: var(--heroui-colors-default-foreground);';
                        }
                        if (value === '#f00') {
                            return 'color: var(--heroui-colors-primary);';
                        }
                        return `color: ${value};`;
                    },
                },
            });
            htmlContent = converter.convert();
        } catch (error) {
        }
    }


    return (
        <div className="wiki-content">
            {htmlContent && (
                <div
                    className="zx-quill-content m-4"
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
            )}
        </div>
    );
};

export default WikiContent;