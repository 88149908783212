import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Avatar,
    Button,
    Divider,
    Tooltip,
    Textarea,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Spacer,
} from "@heroui/react";
import TimeAgo from 'react-timeago';
import germanStrings from 'react-timeago/lib/language-strings/de';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import ReactionList from './ReactionList';
import GiphyGif from './GiphyGif';
import { Icon } from '@iconify/react/dist/iconify.js';
import userService from '@/services/userService';
import ReactionButton from './ReactionButton';

const formatter = buildFormatter(germanStrings);

const CommentComponent = ({ comment, postId, parentCommentId, onReact, onReply, onDelete, onEdit }) => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [isEditing, setIsEditing] = useState(false);
    const [editText, setEditText] = useState(comment.comment || "");
    const [comments, setComments] = useState([]);
    const [userReaction, setUserReaction] = useState(
        comment.reactions?.find(reaction => reaction.author === userService.user.id) || null
    );
    const [isReplying, setIsReplying] = useState(false);
    const [replyText, setReplyText] = useState("");
    const [showReplies, setShowReplies] = useState(false);
    const handleToggleReplies = () => {
        setShowReplies(prev => !prev);
    };

    const handleReplyToggle = () => {
        setIsReplying(!isReplying);
    };

    const handleReplyChange = (e) => {
        setReplyText(e.target.value);
    };

    const handleReplySubmit = () => {
        if (replyText?.trim()) {
            const parentId = parentCommentId || comment.id;
            const replyId = null;
            const replyAuthorId = null;

            onReply(parentId, replyId, replyText, replyAuthorId);

            setReplyText("");
            setIsReplying(false);
            setShowReplies(true);
        }
    };

    const handleReplyToReplySubmit = (replyId, replyAuthorId) => {
        if (replyText?.trim()) {
            const parentId = parentCommentId || comment.id;

            onReply(parentId, replyId, replyText, replyAuthorId);

            setReplyText("");
            setIsReplying(false);
            setShowReplies(true);
        }
    };

    const handleDelete = () => {
        const replyId = comment.isAnswer ? comment.id : null;
        const commentId = parentCommentId || comment.id;

        onDelete(commentId, replyId);
        onOpenChange(false);
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleEditSubmit = () => {
        const commentId = parentCommentId || comment.id;
        const replyId = comment.isAnswer ? comment.id : null;

        onEdit(editText, commentId, replyId);
        setIsEditing(false);
    };

    const handleReact = async (reactionId) => {
        try {
            const updatedReactions = await onReact(postId, parentCommentId ? parentCommentId : comment.id, comment.isAnswer ? comment.id : null, reactionId);
            if (updatedReactions) {
                setUserReaction(updatedReactions.find(reaction => reaction.author === userService.user.id));
                setComments((prevComments) =>
                    prevComments.map((c) =>
                        c.id === comment.id ? { ...c, reactions: updatedReactions } : c
                    )
                );
            }
        } catch (error) {
        }
    };

    return (
        <Card
            fullWidth
            shadow='none' className={`p-2 ${comment.isAnswer ? 'bg-white' : 'bg-default-100'}`}
        >
            <CardHeader className="justify-between">
                <div className="flex gap-4">
                    <Avatar radius="full" size="sm" src={comment.user.avatarUrl} />
                    <div className="flex flex-col gap-1 items-start justify-center">
                        <h4 className="text-small font-semibold leading-none text-default-600">
                            {comment.user.firstname} {comment.user.surname}
                        </h4>
                        <h5 className="text-small tracking-tight text-default-400">
                            <TimeAgo date={comment.created} formatter={formatter} />
                        </h5>
                    </div>
                </div>
                <div className="flex gap-2">
                    {comment.author === userService.user.id ? (
                        <>
                            {!comment.gifMedia && (
                                <Tooltip content="Bearbeiten">
                                    <Button
                                        isIconOnly
                                        color="primary"
                                        variant="light"
                                        aria-label="Bearbeiten"
                                        size="sm"
                                        onPress={handleEditToggle}
                                    >
                                        <Icon className="text-default-400" icon="solar:pen-2-outline" width={20} />
                                    </Button>
                                </Tooltip>
                            )}
                            <Tooltip content="Löschen">
                                <Button
                                    isIconOnly
                                    color="primary"
                                    variant="light"
                                    aria-label="Löschen"
                                    size="sm"
                                    onPress={onOpen}
                                >
                                    <Icon className="text-default-400" icon="solar:trash-bin-minimalistic-outline" width={20} />
                                </Button>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Tooltip content={`${comment.user.firstname} antworten`}>
                                <Button
                                    isIconOnly
                                    color="primary"
                                    variant="light"
                                    aria-label={`${comment.user.firstname} antworten`}
                                    size="sm"
                                    onPress={handleReplyToggle}
                                >
                                    <Icon className="text-default-400" icon="solar:reply-2-outline" width={20} />
                                </Button>
                            </Tooltip>
                            <ReactionButton reaction={userReaction} onReact={handleReact} />
                        </>
                    )}
                </div>
            </CardHeader>
            <CardBody className="py-2 text-sm text-default-800">
                {comment.gifMedia && comment.gifMedia.id ? (
                    <div className="">
                        <GiphyGif gifId={comment.gifMedia.id} />
                    </div>
                ) : (
                    <p>{comment.comment}</p>
                )}
            </CardBody>
            {(isReplying || isEditing) && (
                <Card fullWidth shadow="none" className="p-2 bg-white">
                    <CardBody className="py-2 text-sm text-default-800">
                        <div className="flex items-end gap-2">
                            <Textarea
                                placeholder={
                                    isReplying
                                        ? `${comment.user.firstname} antworten...`
                                        : "Bearbeite deinen Kommentar..."
                                }
                                fullWidth
                                size="sm"
                                variant="bordered"
                                rows={1}
                                minRows={1}
                                maxRows={5}
                                value={isReplying ? replyText : editText}
                                onChange={(e) =>
                                    isReplying
                                        ? setReplyText(e.target.value)
                                        : setEditText(e.target.value)
                                }
                            />
                            <Button
                                isIconOnly
                                color="primary"
                                variant="solid"
                                aria-label="Senden"
                                size="md"
                                radius="full"
                                onPress={() => {
                                    if (isReplying) {
                                        if (comment.isAnswer) {
                                            handleReplyToReplySubmit(comment.id, comment.user.id);
                                        } else {
                                            handleReplySubmit();
                                        }
                                    } else if (isEditing) {
                                        handleEditSubmit();
                                    }
                                }}
                            >
                                <Icon className="text-white" icon="solar:plain-3-outline" width={20} />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            )}
            {(comment.reactions?.length > 0 || comment.replies?.length > 0) && (
                <CardFooter className="flex items-center justify-between">
                    {comment.reactions?.length > 0 && (
                        <ReactionList reactions={comment.reactions} whiteBackground={!comment.isAnswer} />
                    )}
                    <div></div>
                    {comment.replies?.length > 0 && (
                        <Button onPress={handleToggleReplies} className="bg-transparent">
                            <div className="flex gap-1">
                                <p className="font-semibold text-default-400 text-small">
                                    {comment.replies.length}
                                </p>
                                <p className="text-default-400 text-small">{comment.replies.length === 1 ? 'Antwort' : `Antworten`}</p>
                            </div>
                        </Button>
                    )}
                </CardFooter>
            )}
            {showReplies && comment.replies?.length > 0 && (
                <CardBody className="py-0 px-2 pb-2 space-y-2">
                    {comment.replies
                        .slice()
                        .sort((a, b) => new Date(a.created) - new Date(b.created))
                        .map((reply) => (
                            <CommentComponent
                                key={reply.id}
                                comment={reply}
                                postId={postId}
                                parentCommentId={comment.id}
                                onReact={onReact}
                                onReply={onReply}
                                onDelete={onDelete}
                                onEdit={onEdit}
                            />
                        ))}
                </CardBody>
            )}
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} backdrop='blur'>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Kommentar löschen</ModalHeader>
                            <ModalBody>
                                <p>
                                    Möchtest Du diesen Kommentar wirklich löschen? Dies kann nicht rückgängig gemacht werden.
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" variant="light" onPress={onClose}>
                                    Abbrechen
                                </Button>
                                <Button color="primary" onPress={handleDelete}>
                                    Löschen
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </Card>
    );
};

export default CommentComponent;