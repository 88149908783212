import React from "react";
import { Input, Textarea } from "@heroui/react";
import { cn } from "@heroui/react";
import { useAppPushCreator } from "./AppPushCreator";
import AppPromoImageUpload from "@/components/AppPromoImageUpload";

const AppPushCreatorContent = React.forwardRef(({ className, ...props }, ref) => {
  const { state, updateField } = useAppPushCreator();

  const handleImageChange = (file) => {
    updateField("image", file);
  };

  return (
    <>
      <div className="text-3xl font-bold leading-9 text-default-foreground">
        Push-Notification
      </div>
      <div className="py-4 text-default-500">
        Der Titel sollte kurz & präzise sein, die Nachricht nicht länger als zwei Zeilen. Bilder
        werden auf iOS-Geräten nicht gezeigt. Maximale Größe 500 x 500 Pixel, maximum 1MB.
      </div>
      <form
        ref={ref}
        {...props}
        className={cn("flex grid grid-cols-12 flex-col gap-4 py-8", className)}
      >
        <div className="col-span-12 aspect-video">
        <AppPromoImageUpload onImageChange={handleImageChange} />
        </div>
        <Input
          className="col-span-12"
          variant="bordered"
          type="text"
          size="lg"
          label="Titel"
          placeholder="Titel der Mitteilung"
          value={state.title}
          labelPlacement="inside"
          isInvalid={state.titleError}
          onChange={(e) => {
            updateField("title", e.target.value);
            updateField("titleError", false);
          }}
        />
        <Textarea
          className="col-span-12"
          variant="bordered"
          type="text"
          size="lg"
          label="Nachricht"
          value={state.message}
          placeholder="Text der Mitteilung"
          labelPlacement="inside"
          isInvalid={state.messageError}
          onChange={(e) => {
            updateField("message", e.target.value);
            updateField("messageError", false);
          }}
        />
      </form>
    </>
  );
});

AppPushCreatorContent.displayName = "AppPushCreatorContent";

export default AppPushCreatorContent;