import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Chip, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip, User } from "@heroui/react";
import { useNavigate } from "react-router-dom";
import wawiService from "@/services/wawiService";

function WaWiCustomerComponent({ type, id }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const { customer: fetchedCustomer } = await wawiService.getCustomer(type, id);

                if (fetchedCustomer) {
                    setCustomer(fetchedCustomer);
                } else {
                    throw new Error("Kunde nicht gefunden oder leere Antwort erhalten.");
                }
            } catch (err) {
                setError(err.message || "Ein unbekannter Fehler ist aufgetreten.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, type]);

    return (
        <div className="wawi-customer-component">
            {isLoading ? (
                <div className="flex justify-center">
                    <Spinner size="lg" aria-label="Lade Kundendaten..." />
                </div>
            ) : error ? (
                <Card className="w-full rounded-lg border bg-background p-4 shadow">
                    <h3 className="text-red-600 font-bold text-xl">Fehler</h3>
                    <p className="text-red-500">{error}</p>
                </Card>
            ) : customer ? (
                <>
                    <div className="flex justify-between items-center">
                        <User
                            name={`${customer.firstname} ${customer.lastname}`}
                            description={
                                <Link href={`mailto:${customer.email}`} size="sm" isExternal>
                                    {customer.email}
                                </Link>
                            }
                            avatarProps={{
                                src: customer.avatarUrl || "https://firebasestorage.googleapis.com/v0/b/zoxs-newsplace.appspot.com/o/ZOXSY_PLACEHOLDER.png?alt=media&token=6a14b7a0-05bd-4644-90fc-77d3c1d841df",
                                alt: `${customer.firstname} ${customer.lastname}`,
                            }}
                        />
                        <Tooltip showArrow={true} content="Kundendaten aufrufen">
                            <Button color="primary" radius="full" size="sm" >
                                {customer.id}
                            </Button>
                        </Tooltip>
                    </div>
                    {customer.note && customer.note.trim() !== "" && (
                        <Chip className="mt-4" color="primary">Risiko: {customer.note}</Chip>
                    )}
                    <Table hideHeader isStriped isCompact aria-label="User Data Table" shadow="none" fullWidth frame={false} classNames={
                        { wrapper: "p-0 pt-4", }
                    }>
                        <TableHeader>
                            <TableColumn>Property</TableColumn>
                            <TableColumn>Value</TableColumn>
                        </TableHeader>
                        <TableBody>
                            <TableRow key="guthaben">
                                <TableCell className="text-small text-default-500">Guthaben</TableCell>
                                <TableCell className="text-small text-default-500">{`${customer.buyzEuro}€`}</TableCell>
                            </TableRow>
                            <TableRow key="minus-dude">
                                <TableCell className="text-small text-default-500">Minus-Dude?</TableCell>
                                <TableCell className="text-small text-default-500">{customer.minusDude ? "Ja" : "Nein"}</TableCell>
                            </TableRow>
                            <TableRow key="gast">
                                <TableCell className="text-small text-default-500">Gast?</TableCell>
                                <TableCell className="text-small text-default-500">{customer.guest ? "Ja" : "Nein"}</TableCell>
                            </TableRow>
                            <TableRow key="name">
                                <TableCell className="text-small text-default-500">Name</TableCell>
                                <TableCell className="text-small text-default-500">{customer.name}</TableCell>
                            </TableRow>
                            <TableRow key="street-number">
                                <TableCell className="text-small text-default-500">Straße + Nr.</TableCell>
                                <TableCell className="text-small text-default-500">{`${customer.street} ${customer.number}`}</TableCell>
                            </TableRow>
                            <TableRow key="zip-place">
                                <TableCell className="text-small text-default-500">PLZ + Ort</TableCell>
                                <TableCell className="text-small text-default-500">{`${customer.zip} ${customer.place}`}</TableCell>
                            </TableRow>
                            <TableRow key="country">
                                <TableCell className="text-small text-default-500">Land</TableCell>
                                <TableCell className="text-small text-default-500">{customer.country}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            ) : (
                <p className="text-default-500">Keine Kundendaten verfügbar.</p>
            )}
        </div>
    );
}

export default WaWiCustomerComponent;