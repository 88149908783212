import * as React from "react";
import { Button } from "@heroui/react";
import { Icon } from "@iconify/react";
import { cn } from "@heroui/react";

const AppPushCreatorNavigationButtons = React.forwardRef(
  ({ className, onBack, onNext, backButtonProps, nextButtonProps, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "mx-auto my-6 flex w-full items-center justify-center gap-x-4 lg:mx-0",
        className,
      )}
      {...props}
    >
      <Button
        className="rounded-medium border-default-200 text-medium font-medium text-default-500 lg:hidden"
        variant="bordered"
        onPress={onBack}
        {...backButtonProps}
      >
        <Icon icon="solar:arrow-left-outline" width={24} />
        Zurück
      </Button>

      <Button
        className="text-medium font-medium"
        type="submit"
        color="primary"
        radius="full"
        onPress={onNext}
        {...nextButtonProps}
      >
        {nextButtonProps?.children || "Weiter"}
      </Button>
    </div>
  ),
);

AppPushCreatorNavigationButtons.displayName = "AppPushCreatorNavigationButtons";

export default AppPushCreatorNavigationButtons;
