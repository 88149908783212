import { SickHistoryItem, sickHistoryItemFromJson } from "./sickHistoryItem";

export interface SickHistory {
    sickDays: number;
    items: SickHistoryItem[];
}

export const defaultSickHistory: SickHistory = {
    sickDays: 0,
    items: [],
};

export const sickHistoryFromJson = (json: any): SickHistory => {
    return {
      sickDays: json.sickDays || 0,
      items: (json.items || []).map((item: any) => sickHistoryItemFromJson(item)),
    };
  };