import { doc, updateDoc, } from 'firebase/firestore';
import db from '../firebaseConfig';
import Comment from '../models/social/comment';
import { FeedPost } from '../models/social/feedPost';
import { getCookie } from '../helpers/cookieHelper';
import { AppUser } from '@/models/user/appUser';
import { Reaction } from '@/models/social/reaction';
import userService from './userService';
import Story from '@/models/social/story';

class FeedService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.bearerToken = process.env.REACT_APP_BEARER_TOKEN;
  }

  async getPosts(page = 0, size = 5) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}feed?page=${page}&size=${size}&withUsers=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching posts: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of posts');
      }

      const posts = data.map((postData) => FeedPost.fromJson(postData));

      return { postsData: posts };
    } catch (error) {
      console.error('Error fetching posts:', error.message);
      throw error;
    }
  }

  async getFilteredPosts(filters, page = 1, size = 5) {
    try {
      const authToken = getCookie('inteamuser');

      const {
        maxDate,
        minDate,
        locations = [],
        departments = [],
        groups = [],
        authors = [],
        mediaTypes = [],
        commentedByMe = false,
        sortDirection = 'desc',
      } = filters;

      const response = await fetch(`${this.baseUrl}feed/filtered?withUsers=true&page=${page}&size=${size}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify({
          maxDate,
          minDate,
          locations,
          departments,
          groups,
          authorIds: authors,
          mediaTypes,
          commentedByMe,
          sortDirection,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching filtered posts: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of posts');
      }

      const posts = data.map((postData) => FeedPost.fromJson(postData));

      return { postsData: posts };
    } catch (error) {
      throw error;
    }
  }

  async getCommentsForPost(postId) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}feed/post/${postId}/getAllComments?withUser=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching comments: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of comments');
      }

      const comments = data.map((comment) => Comment.fromJson(comment));

      return { comments: comments };
    } catch (error) {
      console.error('Error fetching comments:', error.message);
      throw error;
    }
  }

  async updatePost(id, field, value) {
    const postRef = doc(db, 'posts', id);
    return updateDoc(postRef, { [field]: value });
  }

  async getFeedUsers() {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}feed/u`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching feed users: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of posts');
      }

      const users = data.map((userData) => AppUser.fromJson(userData.user));

      return { users: users };
    } catch (error) {
      console.error('Error fetching posts:', error.message);
      throw error;
    }
  }

  async postPostReaction(postId, reactionId) {
    try {
      const authToken = getCookie('inteamuser');

      if (!authToken) {
        throw new Error('Authentication token not found');
      }

      let url = `${this.baseUrl}feed/post/${postId}/reaction`;

      const emojiId = parseInt(reactionId, 10);
      if (isNaN(emojiId)) {
        throw new Error('Invalid emoji ID');
      }

      const payload = {
        emoji: emojiId,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to post reaction');
      }

      const responseData = await response.json();
      const reactions = responseData.map(reaction => Reaction.fromJson(reaction));

      return reactions;
    } catch (error) {
      console.error('Error posting reaction:', error.message);
      throw error;
    }
  }

  async postReaction(postId, commentId = null, replyId = null, reactionId) {
    try {
      const authToken = getCookie('inteamuser');

      if (!authToken) {
        throw new Error('Authentication token not found');
      }

      let url = `${this.baseUrl}feed/post/${postId}`;
      if (commentId) {
        url += `/comment/${commentId}`;
      }
      url += '/reaction';
      if (replyId) {
        url += `?replyId=${replyId}`;
      }

      const userId = parseInt(userService.user.id, 10);
      if (isNaN(userId)) {
        throw new Error('Invalid user ID');
      }

      const emojiId = parseInt(reactionId, 10);
      if (isNaN(emojiId)) {
        throw new Error('Invalid emoji ID');
      }

      const payload = {
        emoji: emojiId,
        author: userId,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to post reaction');
      }

      const responseData = await response.json();
      const reactions = responseData.map(reaction => Reaction.fromJson(reaction));

      return reactions;
    } catch (error) {
      console.error('Error posting reaction:', error.message);
      throw error;
    }
  }

  async postComment(postId, comment, mainCommentId = null, replyId = null, replyAuthorId = null) {
    try {
      const authToken = getCookie('inteamuser');

      if (!authToken) {
        throw new Error('Authentication token not found');
      }

      const payload = {
        comment: comment,
        mainCommentId :mainCommentId,
        replyId: replyId,
        replyAuthorId: replyAuthorId,
    };

      const response = await fetch(`${this.baseUrl}feed/post/${postId}/comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to post comment');
      }

      const commentData = await response.json();
      const pComment = Comment.fromJson(commentData);

      return pComment;
    } catch (error) {
      console.error('Error posting comment:', error.message);
      throw error;
    }
  }

  async deleteComment(postId, id, replyId = null) {
    try {
      const authToken = getCookie('inteamuser');

      if (!authToken) {
        throw new Error('Authentication token not found');
      }

      const payload = {
        mainCommentId: id,
        id :replyId,
    };

      const response = await fetch(`${this.baseUrl}feed/post/${postId}/comment/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to post comment');
      }

      return true;
    } catch (error) {
      console.error('Error posting comment:', error.message);
      throw error;
    }
  }

  async editComment(postId, comment, commentId = null, replyId = null) {
    try {
      const authToken = getCookie('inteamuser');

      if (!authToken) {
        throw new Error('Authentication token not found');
      }

      const payload = {
        comment: comment,
        commentId :commentId,
        replyId: replyId,
    };

      const response = await fetch(`${this.baseUrl}feed/post/${postId}/comment`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update comment');
      }

      return true;
    } catch (error) {
      console.error('Error updating comment:', error.message);
      throw error;
    }
  }

  async getStories() {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}feed/stories`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching stories: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of stories');
      }

      const stories = data.map((story) => Story.fromJson(story));

      return { stories: stories };
    } catch (error) {
      console.error('Error fetching stories:', error.message);
      throw error;
    }
  }

  async createPost(data) {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}feed/post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
        body: data,
      });

      if (!response.ok) {
        throw new Error(`Error posting content: ${response.statusText}`);
      }

      const result = await response.json();

      if (result === true) {
        return true;
      } else {
        throw new Error('Failed to post content');
      }
    } catch (error) {
      console.error('Error posting content:', error.message);
      throw error;
    }
  }
}

const feedService = new FeedService();
export default feedService;
