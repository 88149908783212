import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import grapesjsMJML from 'grapesjs-mjml';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  Navbar,
  NavbarContent,
  NavbarItem,
  Tooltip,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
} from "@heroui/react";
import { Icon } from '@iconify/react/dist/iconify.js';
import useToast from "../../hooks/useToast";
import mailService from '@/services/mailService';
import { useLocation } from 'react-router-dom';

const MailEditorGrapes = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const editId = query.get('id');
  const duplicateId = query.get('duplicate');
  const isEdit = Boolean(editId);
  const [id, setId] = useState(editId || null);
  const [loading, setLoading] = useState(true);
  const editorRef = useRef(null);
  const { showToast } = useToast();
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [validationError, setValidationError] = useState('');
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  useEffect(() => {
    const initializeEditor = () => {
      const container = document.getElementById('email-editor');
      if (!container) {
        return;
      }
  
      // Initialize GrapesJS
      const editor = grapesjs.init({
        container: '#email-editor',
        avoidInlineStyle: false,
        plugins: [grapesjsMJML],
        pluginsOpts: { [grapesjsMJML]: {} },
        height: 'calc(100vh - 64px)',
      });
  
      editor.on('load', () => {
        editor.DomComponents.clear();
  
        if (isEdit || duplicateId) {
          fetchMailData(editId || duplicateId);
        } else {
          editor.addComponents(`
            <mjml version="3.3.3">
              <mj-body background-color="#F4F4F4" color="#55575d" font-family="Arial, sans-serif">
                <mj-section>
                  <mj-column>
                    <mj-text>Content 1
                    </mj-text>
                  </mj-column>
                </mj-section>
              </mj-body>
            </mjml>
          `);
          setLoading(false);
        }
      });
  
      editorRef.current = editor;
    };
  
    const fetchMailData = async (mailId) => {
      try {
        const mailData = await mailService.getMail(mailId);
        if (mailData) {
          if (mailData.editor !== 'GRAPE') {
            showToast('Achtung: Dieser Content wurde mit einem anderen Editor erstellt!', 'error');
          }
          setTitle(mailData.title || '');
          editorRef.current.addComponents(mailData.mjml || '');
        }
      } catch (error) {
        showToast('Fehler beim Laden des Designs.', 'error');
      } finally {
        setLoading(false);
      }
    };
  
    initializeEditor();
  
    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [editId, duplicateId, isEdit]);


  const handleSave = async () => {
    if (!title || title.length < 5) {
      showToast('Bitte gib einen Titel für das Design ein.', 'error');
      return;
    }

    if (editorRef.current) {
      const mjmlContent = editorRef.current.getHtml();
      const htmlContent = editorRef.current.runCommand('mjml-code-to-html')?.html;

      const mailData = {
        title: title,
        editor: 'GRAPE',
        mjml: mjmlContent,
        html: htmlContent,
      };

      try {
        if (id) {
          const result = await mailService.updateMail(id, mailData);
          if (result && result.id) {
            showToast('Design erfolgreich aktualisiert!', 'success');
          } else {
            showToast(result.error || 'Fehler beim Aktualisieren des Designs.', 'error');
          }
        } else {
          const result = await mailService.addMail(mailData);
          if (result && result.id) {
            setId(result.id);
            showToast(`Design erfolgreich gespeichert! ID: ${result.id}`, 'success');
          } else {
            showToast(result.error || 'Fehler beim Speichern des Designs.', 'error');
          }
        }
      } catch (error) {
        showToast('Ein unerwarteter Fehler ist aufgetreten.', 'error');
      }
    }
  };

  const handleSendMail = async () => {
    if (!email || !subject) {
      setValidationError('Bitte fülle alle Felder aus.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setValidationError('Bitte gib eine gültige E-Mail-Adresse ein.');
      return;
    }

    setValidationError('');

    const htmlContent = editorRef.current?.runCommand('mjml-code-to-html')?.html;
    if (htmlContent) {
      const mailData = {
        emailBody: htmlContent,
        recipient: email,
        subject,
      };

      try {
        const result = await mailService.sendMail(mailData);

        if (result === true) {
          showToast('Mail erfolgreich gesendet!', 'success');
          onOpenChange(false);
        } else {
          showToast(result.error || 'Fehler beim Senden.', 'error');
          onOpenChange(false);
        }
      } catch (error) {
        showToast('Ein unerwarteter Fehler ist aufgetreten.', 'error');
      }
    }
  };

  return (
    (<div className="w-full h-full flex-1">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white z-50">
          <Spinner size="lg" color="primary" />
        </div>
      )}
      <Navbar maxWidth="full">
        <NavbarContent justify="start">
          <NavbarItem>
            <h1 className="text-3xl font-bold leading-9 text-default-foreground">ZOXS Mailer: Designer</h1>
          </NavbarItem>
        </NavbarContent>

        <NavbarContent as="div" className="items-center" justify="end">
          <Input
            placeholder="Name des Designs"
            size="md"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="bordered"
          />
          <Button color="primary" onPress={handleSave}>
            Speichern
          </Button>
          <Tooltip content="Testen">
            <Button
              isIconOnly
              color="primary"
              variant="light"
              aria-label="Testen"
              size="sm"
              onPress={onOpen}
            >
              <Icon className="text-default-400" icon="solar:plain-3-outline" width={20} />
            </Button>
          </Tooltip>
        </NavbarContent>
      </Navbar>
      <div>
        <div id="email-editor"></div>
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} backdrop='blur'>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Test E-Mail senden</ModalHeader>
              <ModalBody>
                <Input
                  label="Betreff"
                  placeholder="E-Mail Betreff"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  variant="bordered"
                />
                <Input
                  label="Empfänger"
                  placeholder="Empfänger E-Mail Adresse"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.replace(/\s/g, ''))}
                  variant="bordered"
                  type="email"
                />
                {validationError && (
                  <p className="text-red-500 mt-2">{validationError}</p>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Abbrechen
                </Button>
                <Button color="primary" onPress={handleSendMail}>
                  Senden
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>)
  );
};

export default MailEditorGrapes;