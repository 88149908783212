import React, { useEffect, useRef } from 'react';
import { BlockManager, BasicType } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider } from 'easy-email-editor';
import { StandardLayout } from 'easy-email-extensions';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
import '../../assets/styles/newsapp.css';
import { Button, Input, Navbar, NavbarContent, NavbarItem } from "@heroui/react";;

const MailEditorEasyEmail = () => {
  const editorContainerRef = useRef(null);

  useEffect(() => {
    const themeLink = document.createElement('link');
    themeLink.rel = 'stylesheet';
    themeLink.href = 'https://unpkg.com/@arco-themes/react-easyemail-zx/css/arco.css';
    themeLink.id = 'email-editor-theme';
    editorContainerRef.current?.appendChild(themeLink);

    const localStylesheet = document.createElement('link');
    localStylesheet.rel = 'stylesheet';
    localStylesheet.href = '/assets/styles/newsapp.css';
    localStylesheet.id = 'newsapp-theme';
    editorContainerRef.current?.appendChild(localStylesheet);

    return () => {
      document.getElementById('email-editor-theme')?.remove();
      document.getElementById('newsapp-theme')?.remove();
    };
  }, []);


  return (
    <div className="w-full h-full flex-1">
      <Navbar maxWidth='full'>
        <NavbarContent justify="start">
          <NavbarItem>
            <h1 className="text-3xl font-bold leading-9 text-default-foreground">ZOXS Mailer: Designer</h1>
          </NavbarItem>
        </NavbarContent>

        <NavbarContent as="div" className="items-center" justify="end">
          <Input
            placeholder="Name des Designs"
            size="md"
            variant='bordered'
          />
          <Button color="primary">Speichern</Button>
        </NavbarContent>
      </Navbar>
      <div ref={editorContainerRef} style={{ position: 'relative' }}>
        <EmailEditorProvider
          data={{
            subject: 'Welcome to Easy-email',
            subTitle: 'Start designing your email!',
            content: BlockManager.getBlockByType(BasicType.PAGE).create({}),
          }}
          height={'calc(100vh - 72px)'}
          autoComplete
          dashed={false}
        >
          {({ values }) => (
            <StandardLayout showSourceCode={true} compact={false} height={'100%'}>
              <EmailEditor />
            </StandardLayout>
          )}
        </EmailEditorProvider>
      </div>
    </div>
  );
};

export default MailEditorEasyEmail;