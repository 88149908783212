import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Input,
    Navbar,
    NavbarContent,
    NavbarItem,
    Tooltip,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Spinner,
} from "@heroui/react";
import { Icon } from '@iconify/react/dist/iconify.js';
import useToast from "../../hooks/useToast";
import mailService from '@/services/mailService';
import { useLocation } from 'react-router-dom';
import grapesjs from 'grapesjs';
import fontsPlugin from '@silexlabs/grapesjs-fonts';
import symbolsPlugin from '@silexlabs/grapesjs-symbols';
import exportPlugin from 'grapesjs-plugin-export';
import ckePlugin from 'grapesjs-plugin-ckeditor';
import tuiPlugin from 'grapesjs-tui-image-editor';
import blocksPlugin from 'grapesjs-blocks-basic';
import formsPlugin from 'grapesjs-plugin-forms';
import navbarPlugin from 'grapesjs-navbar';
import countdownPlugin from 'grapesjs-component-countdown';
import gradientPlugin from 'grapesjs-style-gradient';
import filterPlugin from 'grapesjs-style-filter';
import styleBgPlugin from 'grapesjs-style-bg';
import flexboxPlugin from 'grapesjs-blocks-flexbox';
import sliderPlugin from 'grapesjs-lory-slider';
import tabPlugin from 'grapesjs-tabs';
import tooltipPlugin from 'grapesjs-tooltip';
import customCodePlugin from 'grapesjs-custom-code';
import parserPostCSS from 'grapesjs-parser-postcss';
import typedPlugin from 'grapesjs-typed';
import floatPlugin from 'grapesjs-float'
import rulerPlugin from 'grapesjs-rulers';
import toolboxPlugin from 'grapesjs-plugin-toolbox';
import scriptPlugin from 'grapesjs-script-editor';
import saveBlockPlugin from 'grapesjs-user-blocks';
import tablePlugin from 'grapesjs-table';
import googleAnalyticsPlugin from 'grapesjs-ga';
import calendarPlugin from 'grapesjs-calendar-component';
import codeEditorPlugin from 'grapesjs-component-code-editor';
import rteExtensions from 'grapesjs-rte-extensions';

const WebDesigner = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const editId = query.get('id');
    const duplicateId = query.get('duplicate');
    const isEdit = Boolean(editId);
    const [id, setId] = useState(editId || null);
    const [loading, setLoading] = useState(true);
    const editorRef = useRef(null);
    const { showToast } = useToast();
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [validationError, setValidationError] = useState('');
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    useEffect(() => {
        const initializeEditor = () => {
            const container = document.getElementById('web-editor');
            if (!container) {
                return;
            }

            const editor = grapesjs.init({
                container: '#web-editor',
                plugins: [fontsPlugin, symbolsPlugin, exportPlugin, tuiPlugin, blocksPlugin, formsPlugin, navbarPlugin, countdownPlugin, gradientPlugin, filterPlugin, styleBgPlugin, flexboxPlugin, tabPlugin, tooltipPlugin, customCodePlugin, parserPostCSS, floatPlugin, typedPlugin, floatPlugin, rulerPlugin, scriptPlugin, saveBlockPlugin, tabPlugin, googleAnalyticsPlugin, calendarPlugin, codeEditorPlugin, rteExtensions],
                pluginsOpts: {
                    [fontsPlugin]: {
                        api_key: process.env.REACT_APP_GOOGLE_FONTS_API_KEY,
                    },
                    [symbolsPlugin]: {
                        appendTo: '.gjs-pn-views-container',
                    },
                },
                avoidInlineStyle: false,
                height: 'calc(100vh - 64px)',
            });

            editor.on('load', () => {
                editor.DomComponents.clear();

                if (isEdit || duplicateId) {
                    fetchMailData(editId || duplicateId);
                } else {
                    editor.addComponents(``);
                    setLoading(false);
                }
            });

            editorRef.current = editor;
        };

        const fetchMailData = async (mailId) => {
            try {
                const mailData = await mailService.getMail(mailId);
                if (mailData) {
                    if (mailData.editor !== 'GRAPE') {
                        showToast('Achtung: Dieser Content wurde mit einem anderen Editor erstellt!', 'error');
                    }
                    setTitle(mailData.title || '');
                    editorRef.current.addComponents(mailData.mjml || '');
                }
            } catch (error) {
                showToast('Fehler beim Laden des Designs.', 'error');
            } finally {
                setLoading(false);
            }
        };

        initializeEditor();

        return () => {
            if (editorRef.current) {
                editorRef.current.destroy();
                editorRef.current = null;
            }
        };
    }, [editId, duplicateId, isEdit]);


    const handleSave = async () => {
        if (!title || title.length < 5) {
            showToast('Bitte gib einen Titel für das Design ein.', 'error');
            return;
        }

        if (editorRef.current) {
            const htmlContent = editorRef.current.getHtml();

            const contentData = {
                title: title,
                css: '',
                html: htmlContent,
            };

            try {
                if (id) {
                    const result = await mailService.updateMail(id, contentData);
                    if (result && result.id) {
                        showToast('Design erfolgreich aktualisiert!', 'success');
                    } else {
                        showToast(result.error || 'Fehler beim Aktualisieren des Designs.', 'error');
                    }
                } else {
                    const result = await mailService.addMail(contentData);
                    if (result && result.id) {
                        setId(result.id);
                        showToast(`Design erfolgreich gespeichert! ID: ${result.id}`, 'success');
                    } else {
                        showToast(result.error || 'Fehler beim Speichern des Designs.', 'error');
                    }
                }
            } catch (error) {
                showToast('Ein unerwarteter Fehler ist aufgetreten.', 'error');
            }
        }
    };

    return (
        (<div className="w-full h-full flex-1">
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white z-50">
                    <Spinner size="lg" color="primary" />
                </div>
            )}
            <Navbar maxWidth="full">
                <NavbarContent justify="start">
                    <NavbarItem>
                        <h1 className="text-3xl font-bold leading-9 text-default-foreground">Content-Designer</h1>
                    </NavbarItem>
                </NavbarContent>

                <NavbarContent as="div" className="items-center" justify="end">
                    <Input
                        placeholder="Name des Designs"
                        size="md"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        variant="bordered"
                    />
                    <Button color="primary" onPress={handleSave}>
                        Speichern
                    </Button>
                </NavbarContent>
            </Navbar>
            <div>
                <div id="web-editor"></div>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} backdrop='blur'>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Test E-Mail senden</ModalHeader>
                            <ModalBody>
                                <Input
                                    label="Betreff"
                                    placeholder="E-Mail Betreff"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    variant="bordered"
                                />
                                <Input
                                    label="Empfänger"
                                    placeholder="Empfänger E-Mail Adresse"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.replace(/\s/g, ''))}
                                    variant="bordered"
                                    type="email"
                                />
                                {validationError && (
                                    <p className="text-red-500 mt-2">{validationError}</p>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" variant="light" onPress={onClose}>
                                    Abbrechen
                                </Button>
                                <Button color="primary" onPress>
                                    Senden
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>)
    );
};

export default WebDesigner;