import React from "react";
import { Image, Tabs, Tab, Card, CardBody } from "@heroui/react";
import appleQr from '../assets/svg/apple_qr.svg'
import googleQr from '../assets/svg/google_play_qr.svg'

export default function AppDownloadContent() {
    let tabs = [
        {
            id: "apple",
            icon: <i className="fa-brands fa-apple"></i>,
            label: "AppStore",
            content: <Image
                width={215}
                alt="AppStore QR Code"
                src={appleQr}
            />
        },
        {
            id: "google",
            icon: <i className="fa-brands fa-google-play"></i>,
            label: "Google Play",
            content: <Image
                width={215}
                alt="Google Play QR Code"
                src={googleQr}
            />
        },
    ];

    return (
        <div className="mt-4 flex items-center justify-center flex-col">
            <Tabs aria-label="dl-tabs" items={tabs}>
                {(item) => (
                    <Tab key={item.id} title={
                        <div className="flex items-center space-x-2">
                            {item.icon}
                            <span>{item.label}</span>
                        </div>
                    }>
                        <Card>
                            <CardBody>
                                {item.content}
                            </CardBody>
                        </Card>
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}
