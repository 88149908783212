import React, { useEffect, useRef, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    useDisclosure,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Avatar,
    Image,
    Button,
    Chip,
    Tooltip,
} from "@heroui/react";
import { VideoPlayer } from './VideoPlayer';
import GiphyGif from './GiphyGif';
import ReactionList from './ReactionList';
import TimeAgo from 'react-timeago';
import germanStrings from 'react-timeago/lib/language-strings/de';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import GalleryComponent from './Galllery';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import CommentSection from './CommentSection';
import { FeedPostLabelEnum } from '@/enums/feed';
import ReactionButton from './ReactionButton';
import feedService from '@/services/feedService';
import userService from '@/services/userService';
import { Icon } from '@iconify/react/dist/iconify.js';

const formatter = buildFormatter(germanStrings);

const useDebouncedCallback = (callback, delay) => {
    const timeoutRef = useRef(null);
    return (...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};

const FeedPostDetail = ({ post }) => {
    const postRef = useRef(null);
    const commentsRef = useRef(null);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const [commentCount, setCommentCount] = useState(post.commentCount || 0);
    const updateCommentCount = (change) => {
        setCommentCount((prevCount) => Math.max(prevCount + change, 0));
    };

    const [userReaction, setUserReaction] = useState(
        post.reactions?.find(reaction => reaction.author === userService.user.id) || null
    );

    const [lightboxIndex, setLightboxIndex] = useState(-1);

    const openLightbox = (index) => setLightboxIndex(index);
    const closeLightbox = () => setLightboxIndex(-1);

    const galleryImages = post.imageMedia?.map((image) => ({
        src: image,
        width: 4,
        height: 3,
    })) || [];

    let htmlContent = "";
    if (post.textContent) {
        try {
            const deltaOps = JSON.parse(post.textContent);
            const converter = new QuillDeltaToHtmlConverter(deltaOps, {
                inlineStyles: {
                    color: (value) => {
                        if (!value) return "color: var(--heroui-colors-default-foreground);";
                        if (value === "#f00") return "color: var(--heroui-colors-primary);";
                        return `color: ${value};`;
                    },
                },
            });
            htmlContent = converter.convert();
        } catch (error) {
            console.warn("Invalid text content:", error);
        }
    }

    const synchronizeHeights = () => {
        if (postRef.current && commentsRef.current) {
            const postHeight = postRef.current.offsetHeight;
            commentsRef.current.style.height = `${postHeight}px`;
        }
    };

    const throttledSyncHeights = useDebouncedCallback(synchronizeHeights, 100);

    useEffect(() => {
        const postResizeObserver = new ResizeObserver(() => {
            throttledSyncHeights();
        });

        if (postRef.current) {
            postResizeObserver.observe(postRef.current);
        }

        const commentsResizeObserver = new ResizeObserver(() => {
            throttledSyncHeights();
        });

        if (commentsRef.current) {
            commentsResizeObserver.observe(commentsRef.current);
        }

        window.addEventListener("resize", throttledSyncHeights);

        return () => {
            postResizeObserver.disconnect();
            commentsResizeObserver.disconnect();
            window.removeEventListener("resize", throttledSyncHeights);
        };
    }, [throttledSyncHeights]);

    const handleReact = async (reactionId) => {
        try {
            const updatedReactions = await feedService.postPostReaction(post.id, reactionId);
            if (updatedReactions) {
                setUserReaction(updatedReactions.find(reaction => reaction.author === userService.user.id));
                post.reactions = updatedReactions;
            }
        } catch (error) {
        }
    };

    return (
        <>
            <Card className="w-full rounded-lg border bg-background shadow p-2">
                <CardHeader className="justify-between">
                    <div className="flex gap-4">
                        <Avatar radius="full" size="md" src={post.user.avatarUrl} />
                        <div className="flex flex-col gap-1 items-start justify-center">
                            <h4 className="text-small font-semibold leading-none text-default-600">
                                {post.user.firstname} {post.user.surname}
                            </h4>
                            <h5 className="text-small tracking-tight text-default-400">
                                <TimeAgo date={post.created} formatter={formatter} />
                            </h5>
                        </div>
                    </div>
                    {post.label && FeedPostLabelEnum[post.label.toUpperCase()] && (
                        <Chip
                            avatar={<Avatar showFallback name={FeedPostLabelEnum[post.label.toUpperCase()].emoji} size="sm" />}
                            variant="flat"
                        >
                            {FeedPostLabelEnum[post.label.toUpperCase()].name}
                        </Chip>
                    )}
                </CardHeader>
                <CardBody className="flex flex-row min-h-[300px] w-full p-0 m-0">
                    <div
                        ref={postRef}
                        className="flex-1 max-w-full"
                    >
                        {htmlContent && (
                            <div
                                className="zx-quill-content m-4"
                                dangerouslySetInnerHTML={{ __html: htmlContent }}
                            />
                        )}
                        {post.videoMedia?.length > 0 && (
                            <div className="px-3">
                                {post.videoMedia.map((video, index) => (
                                    <div className='max-h-svh rounded-xl overflow-hidden'><VideoPlayer key={index} videoFile={video} /></div>
                                ))}
                            </div>
                        )}
                        {post.imageMedia?.length > 0 && (
                            <div className="px-3">
                                {post.imageMedia.length === 1 ? (
                                    <Image
                                        isZoomed
                                        className="object-cover w-full h-auto aspect-[1/1]"
                                        alt={`Image ${post.id}`}
                                        src={post.imageMedia[0]}
                                        onClick={() => openLightbox(0)}
                                    />
                                ) : (
                                    <div className="grid grid-cols-3 gap-2">
                                        {post.imageMedia.map((image, index) => (
                                            <div
                                                key={index}
                                                className="overflow-hidden rounded-xl cursor-pointer"
                                                onClick={() => openLightbox(index)}
                                            >
                                                <Image
                                                    isZoomed
                                                    className="object-cover w-full h-full aspect-square border-1"
                                                    alt={`Image ${index + 1}`}
                                                    src={image}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        {post.fileMedia?.length > 0 && (
                            post.fileMedia.map((file, index) => (
                                <div className='px-3'>
                                    <Card onPress={() => window.open(file.url, '_blank')} isPressable
                                        key={index}
                                        fullWidth
                                        shadow="none"
                                        className="p-2 bg-default-100"
                                    >
                                        <CardHeader className="justify-between">
                                            <div className="flex gap-4">
                                                <div className="flex flex-col gap-1 items-start justify-center">
                                                    <h4 className="text-small font-semibold leading-none text-default-600">
                                                        {file.filename}
                                                    </h4>
                                                    <h5 className="text-small tracking-tight text-default-400">
                                                        {(file.size / 1024).toFixed(2)} KB
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="flex gap-2">
                                                <Tooltip content="Download">
                                                    <Button
                                                        isIconOnly
                                                        color="primary"
                                                        variant="light"
                                                        aria-label="Download"
                                                        size="sm"
                                                        onPress={() => window.open(file.url, '_blank')}
                                                    >
                                                        <Icon
                                                            className="text-default-400"
                                                            icon="solar:download-minimalistic-outline"
                                                            width={20}
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                </div>
                            ))
                        )}
                        {post.gifMedia?.[0]?.id && (
                            <div className="px-3 w-full">
                                <GiphyGif gifId={post.gifMedia[0].id} />
                            </div>
                        )}
                    </div>
                    <div
                        ref={commentsRef}
                        className="flex-1 overflow-y-auto hidden lg:block"
                    >
                        <CommentSection id={post.id} updateCommentCount={updateCommentCount} />
                    </div>
                </CardBody>
                <CardFooter className="flex items-center justify-between">
                    <div className="flex gap-4 items-center">
                        <ReactionList reactions={post.reactions} />
                        <Button className="bg-white" onPress={onOpen}>
                            <div className="flex gap-1">
                                <p className="font-semibold text-default-400 text-small">
                                    {commentCount}
                                </p>
                                <p className="text-default-400 text-small">Kommentare</p>
                            </div>
                        </Button>
                    </div>
                    <ReactionButton reaction={userReaction} onReact={handleReact} />
                </CardFooter>
                {lightboxIndex >= 0 && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                        <GalleryComponent
                            images={galleryImages}
                            initialIndex={lightboxIndex}
                            onClose={closeLightbox}
                        />
                    </div>
                )}
            </Card>

            <Modal isOpen={isOpen} scrollBehavior="inside" backdrop='blur' size='5xl' onOpenChange={onOpenChange}>
                <ModalContent>
                    <ModalHeader>Kommentare</ModalHeader>
                    <ModalBody>
                        <CommentSection id={post.id} updateCommentCount={updateCommentCount} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default FeedPostDetail;