import React, { useState, useEffect } from 'react';
import foodService from '../services/foodService';
import { Spinner, Tab, Tabs, Button, Modal, ModalContent, ModalHeader, useDisclosure, Spacer } from "@heroui/react";
import FoodComponent from '../components/Food';
import FoodFormComponent from '../components/FoodForm';
import userService from '../services/userService';
import { Icon } from '@iconify/react';
import Location from '@/enums/location';
import { FoodOffer } from '@/models/food/foodOffer';
import ComingSoon from '@/components/ComingSoon';

function Food() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [offers, setOffers] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedLocation, setSelectedLocation] = useState('wesel');

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const allOffers = await foodService.getFoodOffers();
                const sortedOffers = Object.keys(Location).reduce((acc, location) => {
                    acc[location] = [];
                    return acc;
                }, {});

                allOffers.offerData.forEach(offer => {
                    if (sortedOffers[offer.location]) {
                        sortedOffers[offer.location].push(offer);
                    }
                });
                setOffers(sortedOffers);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        fetchOffers();
    }, []);

    const onSubmitOffer = async (data) => {
        const addedOffer = await foodService.addOffer(data);
        if (addedOffer) {
            const locationKey = addedOffer.location.toLowerCase();
            setOffers(prevOffers => ({
                ...prevOffers,
                [locationKey]: [FoodOffer.fromJson(addedOffer), ...prevOffers[locationKey]],
            }));
        }
    }

    const updateOffer = async (offerId, type) => {
        const fieldToUpdate = type === "cancel" ? "cancelled.milliseconds" : "deliveryTime.milliseconds";
        const value = new Date().getTime();

        const updated = await foodService.updateOffer(offerId, fieldToUpdate, value);
        if (updated) {
            const offer = FoodOffer.fromJson(await foodService.getOfferById(offerId));
            const locationKey = offer.location.toLowerCase();
            const updatedOffers = offers[locationKey].map(o => (o.id === offerId ? { ...o, [type === "cancel" ? "cancelled" : "deliveryTime"]: { milliseconds: value } } : o));
            setOffers(prevOffers => ({
                ...prevOffers,
                [locationKey]: updatedOffers,
            }));
        }
    }

    return (
        <ComingSoon></ComingSoon>
    );

    return (
        <>
            <div className='w-full flex justify-center'>
                <div className="flex-1 max-w-screen-lg content-center overflow-scroll p-4">
                    <div className="flex items-center gap-x-3">
                        <h1 className="text-3xl font-bold leading-9 text-default-foreground">Mittagstisch</h1>
                    </div>
                    <h2 className="mt-2 text-small text-default-500 mb-6">
                        Was steht heute auf der Karte? Ich habe Hunger!
                    </h2>
                    <Tabs
                        fullWidth
                        radius='full'
                        aria-label="Standort Auswahl"
                        onSelectionChange={(key) => setSelectedLocation(key)}
                        selectedKey={selectedLocation}
                        classNames={{
                            cursor: "bg-content1 dark:bg-content1",
                            panel: "w-full p-0 pt-4",
                        }}
                        className='mb-4'
                    >
                        {Object.keys(Location).map(key => (
                            <Tab key={key} title={`${Location[key].emoji} ${Location[key].locationName}`} />
                        ))}
                    </Tabs>

                    {loading ? (
                        <div className="flex justify-center items-center w-full h-96">
                            <Spinner />
                        </div>
                    ) : offers[selectedLocation].length === 0 ? (
                        <div className="flex items-center justify-center flex-col">
                            <h4 className="text-small font-semibold leading-none text-default-600 mb-3">Noch keine Bestellungen ☹️</h4>
                        </div>
                    ) : (
                        offers[selectedLocation].map(offer => (
                            <React.Fragment key={offer.id}>
                                <FoodComponent key={offer.id} offer={offer} onClick={updateOffer} />
                                <Spacer y={4} />
                            </React.Fragment>
                        ))
                    )}
                </div>
            </div>

            <Modal backdrop='blur' size='5xl' isOpen={isOpen} onClose={onClose} scrollBehavior="inside" radius='lg' className="overflow-scroll">
                <ModalContent>
                    <>
                        <ModalHeader className="center">Mittagstisch anlegen</ModalHeader>
                        <div className="flex justify-center" style={{ minWidth: "80%" }}>
                            <FoodFormComponent onSubmit={onSubmitOffer} />
                        </div>
                    </>
                </ModalContent>
            </Modal>

            <Button
                onClick={onOpen}
                iconOnly
                className="red-white-badge rounded-full"
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    height: 50,
                    width: 50,
                    minWidth: 'unset',
                    zIndex: 99,
                }}
            >
                <Icon className="text-white" icon="solar:add-circle-linear" width={22} />
            </Button>
        </>
    );
}

export default Food;